import { Folder } from "../services/FolderService";
import { CLEAR_SELECTED_BUCKET, SelectBucketAction, SET_SELECTED_BUCKET } from "../actions/SelectedBucket";


const initState = null;

export default function selectedBucketReducer(state: Folder | null = initState, action: SelectBucketAction) {
  switch(action.type) {
    case SET_SELECTED_BUCKET:
      return action.bucket;
    case CLEAR_SELECTED_BUCKET:
      return null;
    default: 
      return state;
  }
}