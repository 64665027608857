import DEFAULT_THEME from './DEFAULT_THEME.json';
import TURQUOISE_THEME from './TURQUOISE_THEME.json';

export const ThemeTypes = {
  DEFAULT: 'DEFAULT_THEME',
  BLUE: 'TURQUOISE_THEME'
};

export interface Theme {
  TYPE: string;
  COLORS: {
    primary: {
      main: string;
    };
    secondary: {
      main: string;
    };
    disabled: {
      main: string;
    }
  };
  appColors: any;
  navigation: any;
  app: any;
  appText: any;
  forms: any;
  mainBody: {
    backgroundColor: string
  };
}

export const Themes = {
  DEFAULT_THEME,
  TURQUOISE_THEME
}