import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay, faPause } from '@fortawesome/free-solid-svg-icons';
import { getPlaybackInstance } from '../../services/AudioService';
import styles from './AudioPlaybackElem.module.scss';
import { State } from '../../store';
import { useSelector, shallowEqual } from 'react-redux';

function AudioPlaybackElement(props: any) {
  const [isPlaying, setIsPlaying] = useState(false);

  const [isLoaded, setIsLoaded] = useState(false);

  const playbackInstance = getPlaybackInstance(props.uri, setIsLoaded);

  const { load, play, pause, setOnStatusChangeCallback } = playbackInstance;

  const theme = useSelector((state: State) => state.theme, shallowEqual);

  useEffect(() => {
    setOnStatusChangeCallback((playingBool: boolean) => {
      setIsPlaying(playingBool);
    });
  }, [setOnStatusChangeCallback]);

  const fill = props.playbackFill || theme.appColors?.secondary.color;

  return (
    <div
      className={props.className}
      onClick={async () => {
        if (!isLoaded) {
          load();
          return;
        }
        if (isPlaying) {
          pause();
        }

        if (!isPlaying) {
          play();
        }
      }}
    >
      <FontAwesomeIcon
        className={isLoaded ? styles.playbackButton : styles.playbackButtonDisabled}
        style={{ color: fill }}
        icon={!isPlaying ? faPlay : faPause}
      />
    </div>
  );
}

AudioPlaybackElement.propTypes = {
  uri: PropTypes.string,
  playbackFill: PropTypes.string,
  className: PropTypes.any,
};

export default AudioPlaybackElement;
