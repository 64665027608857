import { Asset, UploadsAction } from '../reducers';
import { Recipient } from '../services/RecipientService';

export const SELECT_ASSET = 'SELECT_ASSET';

export const ADD_RECIPIENT = 'ADD_RECIPIENT';

export const UPDATE_RECIPIENT = 'UPDATE_RECIPIENT';

export const selectAsset = (asset: Asset): UploadsAction => {
  return {
    type: SELECT_ASSET,
    asset,
  };
};

export const addRecipient = (recipient: Recipient) => {
  return {
    type: ADD_RECIPIENT,
    recipient,
  };
};

export const updateRecipient = (recipient: Recipient) => {
  return {
    type: UPDATE_RECIPIENT,
    recipient,
  };
};
