import { createStore, combineReducers, compose } from 'redux';
import {
  User,
  userReducer,
  uploadsReducer,
  recipientReducer,
  progressReducer,
  Asset,
  Recipient,
  requestReducer,
  AppRequestStatus,
} from './reducers';
import { loadState, saveState } from './localStorage';
import { selectedVideoURIReducer } from './reducers/SelectedVideoURIReducer';
import { Theme } from './themes/themeService';
import { themeReducer } from './reducers/theme';
import { firstTimeSignInReducer } from './reducers/FirstTimeSignInReducer';
import { navReducer } from './reducers/NavReducer';
import selectedBucketReducer from './reducers/SelectedBucketReducer';
import { Folder } from './services/FolderService';

export interface State {
  user: User;
  selectedAsset: {
    asset?: Asset,
    recipients: Recipient[]
  };
  selectedRecipient: Recipient;
  selectedVideoUri: string;
  selectedBucket: Folder;
  progress: number;
  request: AppRequestStatus;
  sidebarExpanded: boolean;
  theme: Theme;
  firstTime: boolean;
}

const store = createStore(
  // @ts-ignore
  combineReducers({
    user: userReducer,
    selectedAsset: uploadsReducer,
    selectedRecipient: recipientReducer,
    selectedVideoUri: selectedVideoURIReducer,
    selectedBucket: selectedBucketReducer,
    progress: progressReducer,
    request: requestReducer,
    sidebarExpanded: navReducer,
    theme: themeReducer,
    firstTime: firstTimeSignInReducer
  }),
  loadState(),
  compose(
    /*eslint-disable */
    // @ts-ignore
    window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : (f) => f
    /* eslint-enable */
  )
);

store.subscribe(() => {
  // Save changes to local storage whenever changes are made.
  const state: State = (store.getState() as unknown) as State;

  // This is what will be saved.
  saveState({ user: state.user, theme: state.theme, selectedBucket: state.selectedBucket, selectedAsset: state.selectedAsset });
});

export { store };
