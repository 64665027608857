import React from "react";

export function BucketTealIconSVG(props: any) {
  const onClick = props.onClick || (()=>{});

  const className = props.className || '';

  return(
    <div onClick={onClick} style={props.style || {}} className={className}>
      <svg width="100%" height="100%" viewBox="0 0 18 15" fill="none">
        <path d="M17.976 5.30829C17.9043 5.08738 17.5539 4.30765 16.034 3.65368L15.7134 4.81648C16.0131 4.95064 16.6998 5.17327 16.6197 5.62832C16.5709 5.90352 16.1735 6.15244 15.9262 6.25104C15.5761 6.39053 15.1793 6.42231 14.8041 6.43622C14.8041 6.43622 15.7332 2.17548 15.7134 2.20039C15.7894 1.83535 15.7363 1.59915 15.5444 1.42925C14.6782 0.664474 10.9519 -0.00376328 7.77996 1.59528e-05C4.62117 0.00396697 1.73489 0.63716 0.738238 1.08929C0.45888 1.21573 -0.0908354 1.46739 0.0128438 2.03445C0.0234638 2.09165 2.19497 12.5594 2.19497 12.5594C2.39351 13.1749 2.95042 13.6444 3.50338 13.9766C4.35333 14.4875 5.34818 14.7627 6.3362 14.8964C7.55821 15.0623 8.83116 15.0328 10.038 14.7801C10.9422 14.5908 11.8501 14.2663 12.5834 13.7145C12.9801 13.4158 13.3497 13.0298 13.5012 12.5594L14.3368 8.6335C10.8954 8.63625 7.81524 7.64197 6.26492 7.10411C5.22956 7.73645 3.89145 7.19688 3.70984 6.11636C3.54982 5.16554 4.46925 4.31968 5.41496 4.36039C4.72071 4.41605 4.24929 5.09889 4.46943 5.72985C4.70649 6.40925 5.61116 6.66349 6.1958 6.21101C8.2044 6.75402 10.2521 7.2223 12.3255 7.47225C13.4451 7.60778 14.6244 7.7136 15.7391 7.49114C16.637 7.31232 17.7083 6.83716 17.9553 5.90936C18.0068 5.71353 18.014 5.50739 17.976 5.30829ZM13.1351 2.48898C11.8807 2.82877 10.5946 2.96362 9.31679 3.02529C6.99193 3.13729 4.57383 3.09693 2.29541 2.41357C2.02451 2.33232 1.75379 2.23938 1.48775 2.12755C3.00406 1.32378 5.91248 1.06215 7.85736 1.06456C9.87065 1.06679 13.6792 1.58901 14.3445 2.0676C13.95 2.24677 13.5428 2.37853 13.1351 2.48898Z" fill="#4DC8B7"/>
      </svg>
    </div>
  )
}