import React from 'react';

import { State } from "../../store";
import { useSelector, shallowEqual } from "react-redux";


export function PauseVideoSVG(props: any) {

  const theme = useSelector((state: State) => state.theme, shallowEqual);
  
  const onClick = props.onClick || (()=>{});

  return (
    <div onClick={onClick} style={props.style || {}}>
      <svg style={{position: "absolute", top: 0, left: 0, width: 'inherit', height: 'inherit'}} width="100%" height="100%">
        <g>
          <path fill={theme.appColors?.primary?.color} d="M15,10.61c-1.06,0-1.92,0.86-1.92,1.92v15c0,1.06,0.86,1.92,1.92,1.92s1.92-0.86,1.92-1.92v-15
            C16.92,11.47,16.06,10.61,15,10.61z"/>
          <path fill={theme.appColors?.primary?.color} d="M25,10.61c-1.06,0-1.92,0.86-1.92,1.92v15c0,1.06,0.86,1.92,1.92,1.92s1.92-0.86,1.92-1.92v-15
            C26.92,11.47,26.06,10.61,25,10.61z"/>
        </g>
      </svg>
    </div>
  );
}
