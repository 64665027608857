import { faLock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Asset } from "../../reducers";
import styles from './MemoriesLinks.module.scss';

export function MemorySentAssetsLink(props: any) {

  const history = useHistory();

  const memory = props.memory;

  const [imgURI, setImageURI] = useState('');

  useEffect(() => {
    if(!memory) return;
    if(memory.assets?.length) {
      const thumbsRaw = memory.assets.map((asset: Asset) => {
        return asset.signedUrlThumb;
      });

      const thumbs = thumbsRaw.filter((thumb: any) => {
        return thumb?.length;
      });

      //@ts-ignore
      setImageURI(thumbs[0]);
    }
  }, [])

  return (
    <div className={styles.memoryWrapper} onClick={routeToMemorySentAssets}>
      <div style={{backgroundImage: `url(${imgURI})`}} className={styles.memoryImagePreview} />
      <div className={styles.memoryInfoWrapper}>
        <p className={styles.memoryTitle}>{memory.title}</p>
        <p className={styles.memoryDescription}>{memory.description}</p>
        <p className={styles.memoryCreatedAt}>{moment(memory.createdAt).format('MMMM Do YYYY')}</p>
      </div> 
    </div>
  );

    function routeToMemorySentAssets() {
      history.push('/memoryAssets', { memory: memory });
    }
}

export function MemoryReceivedAssetsLink(props: any) {

  const memory = props.memory;

  const history = useHistory();

  const [imgURI, setImageURI] = useState('');

  const now = new Date();

  const isUnlocked = !!memory?.startDate ? now.getTime() >= new Date(memory.startDate).getTime() : true;

  useEffect(() => {
    if(memory.assets?.length) {
      const thumbsRaw = memory.assets.map((asset: Asset) => {
        return asset.signedUrlThumb;
      });

      const thumbs = thumbsRaw.filter((thumb: any) => {
        return thumb?.length;
      });

      //@ts-ignore
      setImageURI(thumbs[0]);
    }
  }, [])

  return (
    <div className={styles.memoryWrapper} onClick={routeToMemoryReceivedAssets}>
      {isUnlocked ? <img src={imgURI} alt="" className={styles.memoryImagePreview} /> : 
      <div className={styles.memoryLockIconWrapper}>
           <FontAwesomeIcon
              icon={faLock}
              className={styles.closeIcon}
              style={styles.memoryLockIcon} />
      </div>}
      <div className={styles.memoryInfoWrapper}>
        {isUnlocked ?
          <>
            <p className={styles.memoryTitle}>{memory.title}</p>
            <p className={styles.memoryDescription}>{memory.description}</p> 
          </> :
          <>
            <p className={styles.memoryTitle}>Unlocks On:</p>
            <p className={styles.memoryDescription}>{moment(memory.startDate).format('MMMM Do YYYY')}</p> 
          </>
        }
      </div>
    </div>
  );

    function routeToMemoryReceivedAssets() {
      if(isUnlocked) {
        history.push('/memoryAssets', { memory: memory });
      }
    }
}