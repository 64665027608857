import { faChevronLeft, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { logoutUser } from '../../actions';
import { closeSidebar } from '../../actions/nav';
import { logout } from '../../services/AuthService';
import { State } from '../../store';
import { NavThemeSelectorElem } from '../../themes/NavThemeSelectorElem';
import { LogoTextSVG } from '../svgs/LogoTextSVG';
import styles from './Sidebar.module.scss';

export function Sidebar() {
  const [userIsAuthenicated, setUserIsAuthenicated] = useState(false);

  const [userIsLoggedIn, setUserIsLoggedIn] = useState(false);

  const user = useSelector((state: State) => state.user, shallowEqual);

  const sidebarExpanded = useSelector((state: State) => state.sidebarExpanded, shallowEqual);
  
  const theme = useSelector((state: State) => state.theme, shallowEqual);

  const dispatch = useDispatch();

  const location = useLocation();

  const whiteText = {
    color: 'white'
  };

  let history = useHistory();


  useEffect(() => {
    setUserIsAuthenicated(user.tokens.length > 0);
    setUserIsLoggedIn((user.id || 0) > 0);
    let unlisten = history.listen(() => {
      dispatch(closeSidebar());
    })

    return () => {
      unlisten();
    }
  }, [user]);

  async function signout() {
    await logout().finally(() => {
      dispatch(logoutUser());
      dispatch(closeSidebar());
    });
  }

  return (
    <>
    {sidebarExpanded && 
      <div className={styles.sidebarWrapper}>
        <div className={styles.sidebarInner}>
          <FontAwesomeIcon
            icon={faChevronLeft}
            className={styles.closeIcon}
            style={theme.navigation?.navbarMenuIcon} onClick={
              () => {
                dispatch(closeSidebar());
              }
            } />
            <LogoTextSVG className={styles.svgTypographyWrapper} onClick={() => {
              history.push('/')
            }} color="white" />
          <div className={styles.appLinksWrapper}>
          {userIsLoggedIn &&
          <>
            <img src={user.signedUrlProfileImageThumb} alt="" className={styles.sidebarUserProfile} />
            <p className={styles.profileFirstName}>{user.first}</p>
            <div className={styles.appUpperLinksWrapper}>
              <div className={styles.appLink}>
                <Link style={{...(location.pathname === '/buckets' ? whiteText : {}), textDecoration: 'inherit', fontFamily: 'inherit' }} to="/buckets">Buckets</Link>
              </div>
              <div className={styles.appLink}>
                <Link style={{...(location.pathname === '/resetPassword' ? whiteText : {}), textDecoration: 'inherit', fontFamily: 'inherit' }} to="/resetPassword">Reset Password</Link>
              </div>
            </div>
            <div className={styles.appLinkWrapperDivider} />
            <div className={styles.appLowerLinksWrapper}>
              <div className={styles.appLink}>
                <Link style={{...(location.pathname === '/ourStory' ? whiteText : {}), textDecoration: 'inherit', fontFamily: 'inherit' }} to="/ourStory">Luvbucket's Story</Link>
              </div>
              <div className={styles.appLink}>
                <Link style={{...(location.pathname === '/giving' ? whiteText : {}), textDecoration: 'inherit', fontFamily: 'inherit' }} to="/giving">Giving</Link>
              </div>
              <div className={styles.appLink}>
                <Link style={{...(location.pathname === '/tutorial' ? whiteText : {}), textDecoration: 'inherit', fontFamily: 'inherit' }} to="/tutorial">Tutorial</Link>
              </div>
            </div>
          </>
          }
          {!userIsLoggedIn &&
          <>
            <div className={styles.appLink}>
              <Link style={{...(location.pathname == '/login' ? whiteText : {}), textDecoration: 'inherit', fontFamily: 'inherit' }} to="/login">Login</Link>
            </div>
            <div className={styles.appLink}>
              <Link style={{...(location.pathname === '/forgotPassword' ? whiteText : {}), textDecoration: 'inherit', fontFamily: 'inherit' }} to="/forgotPassword">Forgot Pass</Link>
            </div>
            <div className={styles.appLink}>
              <Link style={{...(location.pathname === '/ourStory' ? whiteText : {}), textDecoration: 'inherit', fontFamily: 'inherit' }} to="/ourStory">Luvbucket's Story</Link>
            </div>
            <div className={styles.appLink}>
              <Link style={{...(location.pathname === '/giving' ? whiteText : {}), textDecoration: 'inherit', fontFamily: 'inherit' }} to="/giving">Giving</Link>
            </div>
            <div className={styles.appLink}>
              <Link style={{...(location.pathname === '/tutorial' ? whiteText : {}), textDecoration: 'inherit', fontFamily: 'inherit' }} to="/tutorial">Tutorial</Link>
            </div>
          </>
          }
          </div>
        </div>
        {(user.id || 0) > 0 && (
        <FontAwesomeIcon
          icon={faSignOutAlt}
          className={styles.appHeaderSignOutIcon}
          style={{ ...theme.navigation?.navSignout }}
          onClick={() => {
            logout().finally(() => {
              dispatch(logoutUser());
              history.push('/');
            });
          }}
        />
      )}
      </div>
    }
    </>
  );
}