import React, { Fragment } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import styles from './AppHeader.module.scss';
import { State } from '../../store';
import { logout } from '../../services/AuthService';
import { NavThemeSelectorElem } from '../../themes/NavThemeSelectorElem';
import { LogoTextSVG } from '../svgs/LogoTextSVG';
import { LogoSVG } from '../svgs/LogoSVG';
import { logoutUser } from '../../actions';
import { toggleSidebar } from '../../actions/nav';

export function AppHeader(props: any) {
  const dispatch = useDispatch();
  const user = useSelector((state: State) => state.user, shallowEqual);

  const history = useHistory();

  const theme = useSelector((state: State) => state.theme, shallowEqual);

  return (
    <div className={styles.appHeaderContainer}>
      <div className={styles.svgTypographyWrapper} onClick={() => {
        history.push('/')
      }}>
        <LogoTextSVG color="white"  />
      </div>
      <LogoSVG className={styles.navLogo} />
      <FontAwesomeIcon
        icon={faBars}
        className={styles.hamburgerMenuIcon}
        onClick={() => { 
          dispatch(toggleSidebar());
        }} />
      <div className={styles.appHeaderInner}>
       
      </div>
      {(user.id || 0) > 0 && (
        <FontAwesomeIcon
          icon={faSignOutAlt}
          className={styles.appHeaderSignOutIcon}
          style={{ ...theme.navigation?.navSignout }}
          onClick={() => {
            logout().then(() => {
              dispatch(logoutUser());
              history.push('/');
            });
          }}
        />
      )}
    </div>
  );
}
