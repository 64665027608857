import { UPDATE_USER, LOGOUT_USER } from '../actions/user';

interface Token {
  token: string;
}

export interface User {
  id?: string | number;
  first: string;
  last: string;
  email: string;
  createdAt?: Date;
  updatedAt?: Date;
  tokens: Token[];
  signedUrlProfileImageThumb?: string;
}

export interface UserAction {
  type: string;
  user?: User;
}

const initState: User = {
  id: 0,
  first: 'Guest',
  last: 'User',
  email: 'bounce@luvbuckets.com',
  tokens: [],
};

export function userReducer(state = initState, action: UserAction) {
  switch (action.type) {
    case UPDATE_USER:
      return { ...state, ...action.user };
    case LOGOUT_USER:
      initState.email = state.email;
      return initState;
    default:
      return state;
  }
}
