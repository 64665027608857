import { SET_URI } from '../actions/videoUri';

export interface VideoUriAction {
  type: string;
  uri: string;
}

const initState = '';

export function selectedVideoURIReducer(state = initState, action: VideoUriAction) {
  switch (action.type) {
    case SET_URI:
      return action.uri;
    default:
      return state;
  }
}
