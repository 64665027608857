import { Asset, User } from "../reducers";
import { store } from "../store";
import { apiRequest } from "../helpers/retryReq";
import { Memory } from "./MemoriesService";

export interface Folder {
  id: number;
  memories: Memory[];
  receivingUsers: User[];
  userId: number;
  title: string;
  description: string | null;
  signedUrlProfileImage?: string;
  signedUrlProfileImageThumb?: string;
  shortened: string;
  location: string;
  startDate?: Date;
  endDate?: Date;
  profileImageBase64?: String;
}

export interface FolderInput {
  title: string;
  description?: string;
  receivingUsers?: (User | null)[];
  assets?: Asset[];
  startDate?: Date;
  endDate?: Date;
}

export function createFolder(folder: FolderInput, profileImageBase64: string) {
  return reqInstances.createFolder.init({
    method: 'POST',
    headers: contentType,
    body: JSON.stringify({
      query: createFolderQuery,
      variables: { 
        folder: {...folder, ...(profileImageBase64 ? { profileImageBase64: profileImageBase64 } : {})}
      }
    })
  }, true)
  .then((res: any) => {
    return res?.data?.createFolder;
  });
}

export function getFolder(folderId: number) {
  return reqInstances.getFolder.init({
    method: 'POST',
    headers: contentType,
    body: JSON.stringify({
      query: getFolderQuery,
      variables: { 
        folderId: Number(folderId)
      }
    })
  }, true)
  .then((res: any) => {
    return res?.data?.getFolder;
  });
}

export function getUserFolders() {
  return reqInstances.getUserFolders.init({
    method: 'POST',
    headers: contentType,
    body: JSON.stringify({
      query: getUserFoldersQuery
    })
  }, true).then((res: any) => {
    return res?.data?.getUserFolders;
  });
}

export function getRecievedFolders() {
  return reqInstances.getRecievedFolders.init({
    method: 'POST',
    headers: contentType,
    body: JSON.stringify({
      query: getRecievedFoldersQuery,
      variables: {
        userId: store.getState().user?.id
      }
    })
  }, true).then((res: any) => {
    return res?.data?.getRecievedFolders;
  });
}

export function folderGetUsersRecievedFrom() {
  return reqInstances.folderGetUsersRecievedFrom.init({
    method: 'POST',
    headers: contentType,
    body: JSON.stringify({
      query: folderGetUsersRecievedFromQuery
    })
  }, true).then((res: any) => {
    return res?.data?.folderGetUsersRecievedFrom;
  });
}



export function folderGetUsersSentTo() {
  return reqInstances.folderGetUsersSentTo.init({
    method: 'POST',
    headers: contentType,
    body: JSON.stringify({
      query: folderGetUsersSentToQuery
    })
  }, true).then((res: any) => {
    return res?.data?.folderGetUsersSentTo;
  });
}

export function deleteFolder(folderId: number) {
  return reqInstances.deleteFolder.init({
    method: 'POST',
    headers: contentType,
    body: JSON.stringify({
      query: deleteFolderQuery,
      variables: { 
        folderId
      }
    })
  }, true).then((res: any) => {
    return res?.data?.deleteFolder;
  });
}


export function updateFolder(folder: FolderInput, profileImageBase64: string) {
  return reqInstances.updateFolder.init({
    method: 'POST',
    headers: contentType,
    body: JSON.stringify({
      query: updateFolderQuery,
      variables: {
        folder: {...folder, ...(profileImageBase64 ? { profileImageBase64: profileImageBase64 } : {})}
      }
    })
  }, true).then((res: any) => {
    return res?.data?.updateFolder;
  });
}


const contentType = {'Content-Type': 'application/json'};

const reqInstances = { 
  createFolder: new apiRequest(),
  getFolder: new apiRequest(),
  getUserFolders: new apiRequest(),
  getRecievedFolders: new apiRequest(),
  folderGetUsersRecievedFrom: new apiRequest(),
  folderGetUsersSentTo: new apiRequest(),
  updateFolder: new apiRequest(),
  deleteFolder: new apiRequest()
};

const createFolderQuery = `mutation
        CreateFolder($folder:FolderInput!) {
            createFolder(folder:$folder) {
              id
              startDate
              endDate
              title
              userId
              description
              receivingUsers {
                id
                email
                first
                signedUrlProfileImageThumb
                last
              }
              signedUrlProfileImage
              signedUrlProfileImageThumb
              memories {
                id
                startDate
                endDate
                title
                description
                userId
                assets {
                  userId
                  id
                  title
                  fileName
                  fileNameThumb
                  mimeType
                  shortened
                  recipients {
                      id
                      user {
                          first
                          last 
                          email
                        }
                      startDate
                      endDate
                  }
                  path
                  status
                  assetType
                  signedUrl
                  signedUrlThumb
                }
              }
            }
        }`;

const getFolderQuery = `query
GetFolder($folderId: Float!) {
    getFolder(folderId: $folderId) {
      id
      startDate
      endDate
      title
      userId
      description
      receivingUsers {
        id
        email
        first
        last
        signedUrlProfileImageThumb
      }
      signedUrlProfileImage
      signedUrlProfileImageThumb
      memories {
        id
        startDate
        endDate
        title
        description
        userId
        assets {
          userId
          id
          title
          fileName
          fileNameThumb
          mimeType
          shortened
          recipients {
              id
              user {
                  first
                  last 
                  email
                }
              startDate
              endDate
          }
          path
          status
          assetType
          signedUrl
          signedUrlThumb
        }
      }
    }
}`;

const getUserFoldersQuery = `query
GetUserFolders {
  getUserFolders {
      id
      title
      startDate
      endDate
      userId
      description
      receivingUsers {
        id
        email
        first
        last
        signedUrlProfileImageThumb
      }
      signedUrlProfileImage
      signedUrlProfileImageThumb
      memories {
        id
        startDate
        endDate
        title
        description
        userId
        assets {
          userId
          id
          title
          fileName
          fileNameThumb
          mimeType
          shortened
          recipients {
              id
              user {
                  first
                  last 
                  email
                }
              startDate
              endDate
          }
          path
          status
          assetType
          signedUrl
          signedUrlThumb
        }
      }
    }
}`;

const getRecievedFoldersQuery = `query
GetRecievedFolders {
  getRecievedFolders {
      id
      title
      userId
      startDate
      endDate
      description
      receivingUsers {
        id
        email
        first
        last
        signedUrlProfileImageThumb
      }
      signedUrlProfileImage
      signedUrlProfileImageThumb
      memories {
        id
        startDate
        endDate
        title
        description
        userId
        assets {
          userId
          id
          title
          fileName
          fileNameThumb
          mimeType
          shortened
          recipients {
              id
              user {
                  first
                  last 
                  email
                }
              startDate
              endDate
          }
          path
          status
          assetType
          signedUrl
          signedUrlThumb
        }
      }
    }
}`;

const folderGetUsersRecievedFromQuery = `query
FolderGetUsersRecievedFrom {
  folderGetUsersRecievedFrom {
    id
    first
    last
    email
  }
}`

const folderGetUsersSentToQuery = `query
FolderGetUsersSentTo {
  folderGetUsersSentTo {
    id
    first
    last
    email
  }
}`

const updateFolderQuery = `mutation
UpdateFolder($folder:FolderInput!) {
    updateFolder(folder:$folder) {
      id
      title
      startDate
      endDate
      description
      userId
      signedUrlProfileImage
      signedUrlProfileImageThumb
      receivingUsers {
        id
        email
        first
        last
        signedUrlProfileImageThumb
      }
    }
}`;

const deleteFolderQuery = `mutation
RemoveFolder($folderId: Float!) {
    removeFolder(folderId: $folderId) {
      id
      title
      startDate
      endDate
      signedUrlProfileImage
      signedUrlProfileImageThumb
      description
      userId
      receivingUsers {
        id
        email
        first
        last
        signedUrlProfileImageThumb
      }
    }
}`;