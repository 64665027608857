import React, { useState } from 'react';
import { FormControl, TextField } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './PasswordField.module.scss';
import { FormErrorText } from '../FormErrorText';
import { State } from '../../store';
import { useSelector, shallowEqual } from 'react-redux';
import formStyles from '../FormElems/FormElems.module.scss';
import { InputElem } from '../FormElems/InputElem';

interface Props {
  value: string;
  handleChange: any;
  id?: string;
  label?: string;
  errors?: object;
  touched?: object;
  path?: string;
  onKeyUp?: any;
}

export const PasswordField = (props: Props) => {
  const [visible, setVisible] = useState(false);
  const toggleVisibility = () => {
    setVisible(!visible);
  };
  const label = props.label || 'Password';
  const id = props.id || 'password';
  const onKeyUp = props.onKeyUp || function () { };
  const theme = useSelector((state: State) => state.theme, shallowEqual);
  return (
    <FormControl margin="normal" fullWidth>
      <InputElem
        placeholder={label}
        type={visible ? 'text' : 'password'}
        value={props.value}
        onKeyUp={onKeyUp}
        id={id}
        touched={props.touched}
        errors={props.errors}
        path={(props.path || id || "password")}
        handleChange={props.handleChange}
      />
      <Tooltip className={styles.passwordTooltip} title={visible ? 'Hide Password' : 'Show Password'}>
        {visible ? <FontAwesomeIcon style={{ ...theme.forms?.passwordFieldIcon }} icon={faEye} onClick={toggleVisibility} /> :  <FontAwesomeIcon style={{ ...theme.forms?.passwordFieldIcon }} icon={faEyeSlash} onClick={toggleVisibility} />}
      </Tooltip>
      <FormErrorText errors={props.errors} touched={props.touched} path={id} />
    </FormControl>
  );
};
