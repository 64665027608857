import React, { useState, useEffect } from 'react';
import { State } from '../../store';
import { useSelector, shallowEqual } from 'react-redux';
import { PlayVideoSVG } from '../svgs/PlayVideoSVG';
import { PauseVideoSVG } from '../svgs/PauseVideoSVG';
import { ContractVideoSVG } from '../svgs/ContractVideoSVG';
import styles from './VideoControls.module.scss';
import { stat } from 'fs';
import { ExpandVideoSVG } from '../svgs/ExpandVideoSVG';

export function VideoControls(props: any) {

  const [isPlaying, setIsPlaying] = useState(false);

  const videoControls = props.videoControls;

  const theme = useSelector((state: State) => state.theme, shallowEqual);

  const [timeElapsed, setTimeElapsed] = useState(0);

  const [isFullscreen, setIsFullscreen] = useState(false);

  useEffect(() => {
    if(!videoControls) return;
      const callback = (state: any) => {
        let dividend = state.currentTime * 100;
        let divisor = state.duration;
        setTimeElapsed(Math.floor(dividend/divisor));
        setIsPlaying(!state.paused);
        setIsFullscreen(state.isFullscreen);
        if(state.currentTime === state.duration) {
          videoControls.video.seek(0);
        }
      };

      if (videoControls.subscribeToStateChange) {
        videoControls.subscribeToStateChange(callback);
      }
  }, [videoControls]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.playbackButtonWrapper} style={{...theme.mainBody }}>
        <div className={styles.touchableWrapper} onClick={async () => {
          if (!videoControls) {
            return;
          }


          if (isPlaying) {
            // @ts-ignore
            const playbackRes = await videoControls.video.pause();
          }

          if (!isPlaying) {
            // @ts-ignore
            const playbackRes = await videoControls.video.play();
          }
        }}>
        {!isPlaying && 
          <PlayVideoSVG style={{aspectRatio: 1}} />
        }
        {isPlaying && 
          <PauseVideoSVG style={{aspectRatio: 1}} />
        }
        </div>
      </div>
      <div className={styles.sliderWrapper}>
        <div className={styles.sliderBg} style={{ ...theme.mainBody }}>
          <div className={styles.sliderFg} style={{ backgroundColor: theme.appColors.primary.color, width: (timeElapsed+"%") }}></div>
          <div className={styles.slider} style={{ ...theme.mainBody, left: (timeElapsed+"%") }}>
            <div className={styles.touchableWrapper} onClick={(e: any) => {

            }}></div>
          </div>
        </div>
      </div>
      <div className={styles.divButtonWrapper} style={{ ...theme.mainBody }}>
        <div className={styles.touchableWrapper} onClick={() => {
          videoControls.toggleFullscreen();
        }}>
            {isFullscreen && <ContractVideoSVG style={{aspectRatio: 1}} />}
            {!isFullscreen &&  <ExpandVideoSVG style={{aspectRatio: 1}} />}
        </div>
      </div>
    </div>
  );
}