import React from "react";
import { Link } from "react-router-dom";
import localStyles from './TermsOfUseView.module.scss';
export function TermsOfUseView(props: any) {
  return (
    <div className={localStyles.mainContainer}>
      <p style={styles.header} className={localStyles.header}>LUVBUCKETS, INC.</p>
      <p style={styles.header} className={localStyles.header}>TERMS OF USE</p>
      <p style={styles.paragraph}>
      Last updated: March 1st 2021
      </p>
      <p style={styles.paragraph}>
        Please read these Terms carefully. By using LuvBuckets or signing up for an account, you’re agreeing to these Terms, which will result in a legal agreement between you and LuvBuckets (“Agreement”). LuvBuckets (“LuvBuckets,” “we,” or “us”) is an online messaging platform (the “App”) that allows you to schedule messages for later delivery to recipients of your choice. The App is offered through our websites, including https://www.LuvBuckets.com, and any other website or mobile application owned, operated or controlled by us (we’ll collectively refer to these as the “LuvBuckets Site,” and together with the App, the “Service”). LuvBuckets is a Massachusetts corporation whose legal name is LuvBuckets, Inc. LuvBuckets has employees, independent contractors, and representatives (“our Team”). As a customer of the Service or a representative of an entity that’s a customer of the Service, you’re a “Member” according to this Agreement (or “you”).
      </p>
      <p style={styles.paragraph}>
      These Standard Terms of Use (“Terms”) define the terms and conditions under which you’re allowed to use the Service in accordance with the Agreement, which in addition to our <PrivacyPolicy />, describe how we’ll treat your account and the data we collect and process about you and your contacts while you’re a Member. If you don’t agree to these Terms, you must immediately discontinue your use of the Service.
      </p>
      <p style={styles.paragraph}>
      If you download the App through the Apple App Store, the Google Play Store, or other app store or distribution platform (“App Provider”), you acknowledge and agree that: (i) these Terms are between us, and not with the App Provider, and that we are responsible for the Service, not the App Provider; (ii) the App Provider has no obligation to furnish maintenance and support services or handle any warranty claims; (iii) the App Provider is not responsible for addressing any claims you or any third party have relating to the App; (iv) the App Provider is a third party beneficiary of these Terms as related to your use of the App; and (v) the App Provider will have the right to enforce these Terms as related to your use of the App against you.
      </p>
      <p style={styles.paragraph}>
      If you have any questions about our Terms, feel free to contact us.
      </p>
      <p style={styles.paragraph}>
      Account
      </p>
      <p style={styles.paragraph}>
      1. Eligibility
      </p>
      <p style={styles.paragraph}>
      In order to use the Service, you must:
      </p>
          <p style={styles.subText}>1. be at least eighteen (18) years old and able to enter into contracts;</p>
          <p style={styles.subText}>2. complete the account registration process;</p>
          <p style={styles.subText}>3. agree to these Terms;</p>
          <p style={styles.subText}>4. provide true, complete, and up-to-date contact and billing information;</p>
          <p style={styles.subText}>5. not be based in Cuba, Iran, North Korea, Syria, or any other territory that is subject to a U.S. government embargo, or that has been designated by the U.S. government as a “terrorist-supporting” country; and</p>
          <p style={styles.subText}>6. not be listed on any U.S. government list of prohibited or restricted persons.</p>
      <p style={styles.paragraph}>
      By using the Service, you represent and warrant that you meet all the requirements listed above, and that you won’t use the Service in a way that violates any laws or regulations. Note that by representing and warranting, you are making a legally enforceable promise.
      </p>
      <p style={styles.paragraph}>
      LuvBuckets may refuse service, close accounts of any Members, and change eligibility requirements at any time.
      </p>
      <p style={styles.paragraph}>2. Term</p>
      <p style={styles.paragraph}>When you sign up for an account and agree to these Terms, the Agreement between you and LuvBuckets is formed, and the term of the Agreement (the “Term”) will begin. The Term will continue for as long as you have a LuvBuckets account or until you or we terminate the Agreement in accordance with these Terms, whichever happens first. If you sign up for an account on behalf of a company or other entity, you represent and warrant that you have the authority to accept these Terms and enter into the Agreement on its behalf.</p>
      <p style={styles.paragraph}>3. Closing Your Account</p>
      <p style={styles.paragraph}>You or LuvBuckets may terminate the Agreement at any time and for any reason by terminating your LuvBuckets account or giving notice to the other party. We may suspend the Service to you at any time, with or without cause. If we terminate your account without cause, and your account is a paid account, we’ll refund a prorated portion of your monthly prepayment for a Monthly Plan or reimburse you for unused Pay as You Go Credits. We won’t refund or reimburse you in any other situation, including if your account is suspended or terminated for cause, such as for a breach or violation of the Agreement. If your account is inactive for 24 or more months, we may terminate your account and you won’t be entitled to a refund for a prepaid month or reimbursement for unused Pay as You Go Credits. Once your account is terminated, you acknowledge and agree that we may permanently delete your account and all the data associated with it, including your Content. Usernames are unique and can only be used once. If your account has been terminated, the username will no longer be available for use on any future accounts and cannot be reclaimed.</p>
      <p style={styles.paragraph}>4. Changes</p>
      <p style={styles.paragraph}>We may change any of the Terms by posting revised Terms on our LuvBuckets Site. Unless you terminate your account, the new Terms will be effective immediately upon posting and apply to any continued or new use of the Service. We may change the Service or any features of the Service at any time, and we may discontinue the Service or any features of the Service at any time.</p>
      <p style={styles.paragraph}>5. Account and Password</p>
      <p style={styles.paragraph}>You’re responsible for keeping your account name and password confidential. You’re also responsible for any account that you have access to and any activity occurring in such account (other than activity that LuvBuckets is directly responsible for that isn’t performed in accordance with your instructions), whether or not you authorized that activity. You’ll immediately notify us of any unauthorized access or use of your accounts. We’re not responsible for any losses due to stolen or hacked passwords. We don’t have access to your current password, and for security reasons, we may only provide you with instructions on how to reset your password. We have the right to update any of your contact information in your account for billing purposes. In addition, you represent and warrant that all information you provide to us when you establish an account, and when you access and use the Service, is and will remain complete and accurate. We may contact you or any authorized user or login added to your account based on the information provided in your account.</p>
      <p style={styles.paragraph}>6. Account Disputes</p>
      <p style={styles.paragraph}>We don’t know the inner workings of your organization or the nature of your personal relationships. You won’t request access to or information about an account that’s not yours, and you’ll resolve any account-related disputes directly with the other party. We decide who owns an account based on a number of factors, including the content in that account, and the contact and profile information listed for that account. In cases where differing contact and profile information is present or we are unable to reasonably determine ownership, we’ll require you to resolve the matter through proper channels outside of LuvBuckets.</p>
      <p style={styles.paragraph}>When a dispute is identified, we may suspend any account associated with the dispute, including disabling login and sending capabilities, to protect the security and privacy of the data held within the account until the dispute is properly resolved.</p>
      <p style={styles.paragraph}>7. Payment</p>
      <p style={styles.paragraph}>When you sign up for a paid plan (“Monthly Plan”), you agree to monthly recurring billing, starting on the date you sign up. Billing occurs on the same day each month, based on the date that you signed up for the Service. Payments are due for any month on the same or closest date to the day you made your first monthly payment (the “Pay Date”).</p>
      <p style={styles.paragraph}>8. Free Plans</p>
      <p style={styles.paragraph}>If you use the Service under the free plan (“Free Plan”) and you want to go over the Free Plan limits, you’ll be required to upgrade your account to, and select and pay for, a Monthly Plan. Monthly Plans are described in Section 7 above. If you exceed your Free Plan limits, or otherwise upgrade your account to a paid Monthly Plan, you’ll only be provided one complimentary downgrade to the Free Plan (provided your use of the Service qualifies for the Free Plan again). If you go over the limits for the Free Plan more than once, you’ll no longer qualify for the Free Plan, even if your future usage is within the limits of the Free Plan.</p>
      <p style={styles.paragraph}>9. Pay as You Go Credits</p>
      <p style={styles.paragraph}>You may also buy email credits to use the Service (“Pay as You Go Credits”) as explained on our LuvBuckets Site. You will have an opportunity to review current rates for Pay as You Go Credits in your account prior to purchase. If you choose to buy Pay as You Go Credits, you’ll have access to the premium features of the Service, and other than the monthly payment requirement, all of these Terms will still apply to you. Pay as You Go Credits roll over each month, and expire 12 months after purchase. Pay as You Go Credits have no cash value, cannot be refunded or redeemed for cash, and represent a limited license to use the Service for the specified volume and type of service.</p>
      <p style={styles.paragraph}>10. Debit and Credit Cards</p>
      <p style={styles.paragraph}>As long as you’re a Member with a Monthly Plan or otherwise have an outstanding balance with us, you’ll provide us with valid debit or credit card (“card”) information and authorize us to deduct your charges against that card. You’ll replace the information for any card that expires with information for a valid one. If your card is automatically replaced with a new card by a payment processor, you acknowledge and agree that we’re authorized to deduct any charges on your account against the new card. Anyone using a card represents and warrants that they are authorized to use that card, and that any and all charges may be billed to that card and won’t be rejected. If we’re unable to process your card order, we’ll try to contact you by email and may suspend your account until your payment can be processed.</p>
      <p style={styles.paragraph}>11. Refunds</p>
      <p style={styles.paragraph}>We’ll give you a refund for a prepaid month or reimburse you for unused Pay as You Go Credits if we stop providing the Service and terminate your account without cause. You won’t be entitled to a refund or credit from us under any other circumstances. We may, at our sole discretion, offer a refund, discount or credit.</p>
      <p style={styles.paragraph}>12. Billing Changes</p>
      <p style={styles.paragraph}>We may change our fees, including our charges for Monthly Plans and Pay as You Go Credits, at any time by posting a new pricing structure to our LuvBuckets Site or in your account and/or sending you a notification by email. Quoted fees don’t include sales or other transaction-based taxes of any kind</p>
      <p style={styles.paragraph}>Rights</p>
      <p style={styles.paragraph}>13. Feedback and Proprietary Rights</p>
      <p style={styles.paragraph}>We own all proprietary rights in the Service, including, but not limited to, patents, trademarks, service marks, trade secrets, copyrights, and other intellectual property rights.</p>
      <p style={styles.paragraph}>You shall retain all right, title, and interest in and to the material, content, data, and information (including your personal information and the personal information of others) you submit to LuvBuckets in the course of using the Service or which LuvBuckets otherwise retrieves or accesses at your direction or with your permission (collectively, your “Content”). Subject to these Terms, you grant us permission to use or disclose your Content (including any personal information therein) only as necessary to provide the Service to you and/or as otherwise permitted by these Terms. You represent and warrant that: (i) you own or have otherwise obtained all necessary rights, releases, and permissions to submit all your Content to the Service and to grant the rights granted to us in these Terms and (ii) your Content and its submission and use as you authorize in these Terms will not violate (1) any applicable law, (2) any third-party intellectual property, privacy, publicity, or other rights, or (3) any of your or third-party policies or terms governing your Content.</p>
      <p style={styles.paragraph}>You may provide or we may ask you to provide suggestions, comments, input or other feedback (“Feedback”) regarding the Service. If you provide us with any Feedback, then you grant us a royalty-free, non-exclusive, worldwide, sublicensable, and transferable license to use, reproduce, publicly display, distribute, modify, and publicly perform the Feedback as we see fit. Any Feedback you choose to provide is given entirely voluntarily. You understand that you will not receive any compensation for your Feedback, and that we may use any Feedback you provide to improve the Service or to develop new features and services.</p>
      <p style={styles.paragraph}>14. Privacy Policy</p>
      <p style={styles.paragraph}>Your privacy is important to us. Please read our <PrivacyPolicy /> for information regarding how we collect, use, and disclose your personal information and the privacy rights available to you when you use and interact with the Service.</p>
      <p style={styles.paragraph}>For the purposes of European privacy laws, for much of the personal information we collect and process through the Service, we act as a processor; however, there are certain instances, as described in our <PrivacyPolicy />, where we process personal information, including Content and other data from Member accounts, as a controller for important business purposes (e.g., for billing, administrative, security, and product improvement purposes).</p>
      <p style={styles.paragraph}>When we, as a controller, process data that originates from the European Economic Area, UK, or Switzerland in a country that has not been found to provide an adequate level of protection under applicable data protection law, we shall process such data in compliance with the European Commission decision C(2004) 5271 standard contractual clauses for controllers (as updated, amended, or replaced from time to time), the terms of which are incorporated herein by reference. For the purposes of the descriptions in the standard contractual clauses: (a) LuvBuckets shall be the “data importer” and you shall be the “data exporter”; (b) the data importer selects option (iii) for the purposes of Clause 2(h) of the Model Clauses; and (c) the optional clauses are expressly not included. The details of the transfer are as follows: (i) the purposes of transfer, categories of data and data subjects are described in our <PrivacyPolicy />; (ii) sensitive data: n/a; and (iii) contact points for data protection enquiries are as set forth in these Terms.</p>
      <p style={styles.paragraph}>15. Right to Review Content</p>
      <p style={styles.paragraph}>We may view, copy, and internally distribute Content from your and account to create algorithms and programs (“Tools”) that help us spot problem accounts and improve the Service. We use these Tools to find Members who violate these Terms or laws and to study data internally to make the Service smarter and create better experiences for Members and their contacts. We may aggregate and anonymize data, including from your Content, to create statistical information. Aggregated anonymized statistical information may be shared externally for research, marketing, or other lawful purposes.</p>
      <p style={styles.paragraph}>Rules and Abuse</p>
      <p style={styles.paragraph}>16. General Rules</p>
      <p style={styles.paragraph}>LuvBuckets doesn’t allow accounts that promote or incite harm toward others or that promote discriminatory, hateful, or harassing Content. To this end, we may suspend or terminate your account if you send any Content that we determine, in our sole discretion, contains either of the following:</p>
          <p style={styles.subText}>• A Threat of Physical Harm. This means any statement, photograph, advertisement, or other Content that in our sole judgment could be reasonably perceived to threaten, advocate, or incite physical harm to or violence against others.</p>
          <p style={styles.subText}>• Hateful Content. This means any statement, image, photograph, advertisement, or other Content that in our sole judgment could be reasonably perceived to harm, threaten, promote the harassment of, promote the intimidation of, promote the abuse of, or promote discrimination against others based solely on race, ethnicity, national origin, sexual orientation, gender, gender identity, religious affiliation, age, disability, disease, or immigration status.</p>
      <p style={styles.paragraph}>LuvBuckets also does not allow the distribution of Content that is, in our sole discretion, materially false, inaccurate, or misleading in a way that could deceive or confuse others about important events, topics, or circumstances.</p>
      <p style={styles.paragraph}>If you violate any of these rules, then we may issue a warning to, suspend, or terminate your account.</p>
      <p style={styles.paragraph}>17. Reporting Abuse</p>
      <p style={styles.paragraph}>If you think anyone is violating any of these Terms, please notify us immediately. If you received spam you think came from a LuvBuckets Member, please report it to us at ______________. If you think anyone has posted material that violates any protected marks or copyrights, then you can notify us at ______________.
      <p style={styles.paragraph}>18. Bandwidth Abuse/Throttling</p>
      <p style={styles.paragraph}></p>You may only use our bandwidth for your Content. We provide image and data hosting only for your use of the Service, so you may not host images on our servers for anything else. We may throttle your sending or connection at our discretion.</p>
      <p style={styles.paragraph}>19. Compliance with Laws</p>
      <p style={styles.paragraph}>You represent and warrant that your use of the Service will comply with all applicable laws and regulations. You’re responsible for determining whether the Service is suitable for you to use in light of your obligations under any regulations like HIPAA, GLB, Data Protection Laws, United States export control laws and regulations and economic sanctions laws and regulations (“U.S. Export Control Laws and Regulations”), or other applicable laws. If you’re subject to regulations (like HIPAA) and you use the Service, then we won’t be liable if the Service doesn’t meet those requirements. You may not use the Service for any unlawful or discriminatory activities, including acts prohibited by the Federal Trade Commission Act, Fair Credit Reporting Act, Equal Credit Opportunity Act, Children's Online Privacy Protection Act, or any other applicable laws.</p>
      <p style={styles.paragraph}>You agree to indemnify and hold us harmless from any losses, including all legal fees and expenses, that result from your breach of this Section 19.</p>
      <p style={styles.paragraph}>20. Export Controls</p>
      <p style={styles.paragraph}>The software that supports the App (the “Software”) is subject to U.S. Export Control Laws and Regulations. None of the Software may be downloaded or otherwise exported or re-exported in violation of U.S. Export Control Laws and Regulations and any other applicable export laws and regulations (collectively, “Export Control Laws”). Therefore, you agree that you won’t, directly or indirectly through a third party, allow the Software or your Content to be accessed or generated from within, or distributed or sent to, any prohibited or embargoed country as mentioned in any Export Control Laws. In addition, you certify that neither you nor any principals, officers, directors, or any person or entity you know to be directly involved with the use of the Service is designated on any U.S. government list of prohibited or restricted persons.</p>
      <p style={styles.paragraph}>Liability</p>
      <p style={styles.paragraph}>21. Limitation of Liability</p>
      <p style={styles.paragraph}>To the maximum extent permitted by law, you acknowledge and agree that (i) you assume full responsibility for any loss that results from your use of the Service; (ii) we and our Team won’t be liable for any indirect, punitive, special, or consequential damages under any circumstances, even if they’re based on negligence or we’ve been advised of the possibility of those damages; and (iii) in any calendar month, our total liability to you arising under or in connection with the Agreement—whether in contract, tort (including negligence), breach of statutory duty, or otherwise—will be no more than what you paid us for the Service the preceding month.</p>
      <p style={styles.paragraph}>For the avoidance of doubt, in no instance will we or our Team be liable for any losses or damages you suffer if you use the Service in violation of these Terms, regardless of whether we terminate or suspend your account due to such violation.</p>
      <p style={styles.paragraph}>22. No Warranties</p>
      <p style={styles.paragraph}>To the maximum extent permitted by law, we provide the Service as-is. This means that, except as expressly stated in these Terms, we don’t provide warranties, conditions, or undertakings of any kind in relation to the Service, either express or implied. This includes, but isn’t limited to, warranties of merchantability and fitness for a particular purpose, which are, to the fullest extent permitted by law, excluded from the Agreement. Since Members use the Service for a variety of reasons, we can’t guarantee that it’ll meet your specific needs.</p>
      <p style={styles.paragraph}>23. Indemnity</p>
      <p style={styles.paragraph}>You agree to indemnify and hold us and our Team harmless from any losses, including legal fees and expenses that directly or indirectly result from any claims you make that aren’t allowed under these Terms due to a “Limitation of Liability” or other provision. You also agree to indemnify and hold us harmless from any losses, including legal fees and expenses, that directly or indirectly result from (i) your Content, (ii) your use of the Service, (iii) your violation of any laws or regulations, (iv) third-party claims that you or someone using your password did something that, if true, would violate any of these Terms, (v) any misrepresentations made by you, or (vi) a breach of any representations or warranties you’ve made to us.</p>
      <p style={styles.paragraph}>24. Equitable Relief</p>
      <p style={styles.paragraph}>Your violation of these Terms may cause irreparable harm to us and our Team. Therefore, we have the right to seek injunctive relief or other equitable relief if you violate these Terms (meaning we may request a court order to stop you).</p>
      <p style={styles.paragraph}>25. Subpoena Fees</p>
      <p style={styles.paragraph}>If we have to provide information in response to a subpoena, court order, or other legal, governmental, or regulatory inquiry related to your account, then we may charge you for our costs. These fees may include attorney and employee time spent retrieving the records, preparing documents, and participating in a deposition.</p>
      <p style={styles.paragraph}>26. Disclaimers</p>
      <p style={styles.paragraph}>We and our Team aren’t responsible for the behavior of any third parties, agencies, linked websites, or other Members, including third-party applications, products, or services for use in connection with the Service (each, a “Third-Party Integration”). Your use of any Third-Party Integration and rights with respect to such Third-Party Integration are solely between you and the applicable third party. We are not responsible for the privacy, security or integrity of any Third-Party Integration or the practices and policies of any Third-Party Integration. We make no warranties of any kind and assume no liability of any kind for your use of any Third-Party Integration.</p>
      <p style={styles.paragraph}>Other Important Stuff</p>
      <p style={styles.paragraph}>27. Assignments</p>
      <p style={styles.paragraph}>You may not assign any of your rights under this Agreement to anyone else. We may assign our rights to any other individual or entity at our discretion.</p>
      <p style={styles.paragraph}>28. Choice of Law</p>
      <p style={styles.paragraph}>The Commonwealth of Massachusetts’ laws, except for conflict of laws rules, will apply to any dispute related to the Agreement or the Service. You acknowledge and agree that any dispute related to the Agreement or the Service itself will be decided by the state or federal courts in Suffolk County, Massachusetts, and each party consents to personal jurisdiction in those courts.</p>
      <p style={styles.paragraph}>29. Force Majeure</p>
      <p style={styles.paragraph}>We won’t be held liable for any delays or failure in performance of any part of the Service, from any cause beyond our control. This includes, but is not limited to, acts of god, changes to law or regulations, embargoes, war, terrorist acts, riots, fires, earthquakes, nuclear accidents, pandemics, floods, strikes, power blackouts, volcanic action, unusually severe weather conditions, acts of hackers, or third-party internet service providers.</p>
      <p style={styles.paragraph}>30. Survivability</p>
      <p style={styles.paragraph}>Even if this Agreement is terminated, the following sections will continue to apply: Feedback and Proprietary Rights, Compliance with Laws, Limitation of Liability, No Warranties, Indemnity, Choice of Law, Severability, and Entire Agreement.</p>
      <p style={styles.paragraph}>31. Severability</p>
      <p style={styles.paragraph}>If it turns out that a section of these Terms isn’t enforceable, then that section will be removed or edited as little as required, and the rest of the Agreement will still be valid.</p>
      <p style={styles.paragraph}>32. Interpretation</p>
      <p style={styles.paragraph}>The headers are provided only to make these Terms easier to read and understand. The fact that we wrote these Terms won’t affect the way the Agreement is interpreted.</p>
      <p style={styles.paragraph}>33. Waiver</p>
      <p style={styles.paragraph}>If we don’t immediately take action on a violation of these Terms, we’re not giving up any rights under the Terms, and we may still take action at some point.</p>
      <p style={styles.paragraph}>34. No Changes in Terms at Request of Member</p>
      <p style={styles.paragraph}>Because we have so many Members, we can’t change these Terms for any one Member or group.</p>
      <p style={styles.paragraph}>35. Further Actions</p>
      <p style={styles.paragraph}>You’ll provide all documents and take any actions necessary to meet your obligations under these Terms.</p>
      <p style={styles.paragraph}>36. Notification of Security Incident</p>
      <p style={styles.paragraph}>If we become aware of a security incident related to our systems or databases that contain personal information of you or your contacts, we’ll notify you if required by law. In that event, we’ll also provide you with information about that incident so that you can evaluate the consequences to you and any legal or regulatory requirements that may apply to you, unless we’re prevented from doing so by legal, security or confidentiality obligations. Notifying you of a security incident or cooperating with you to respond to one will not be deemed an acknowledgement or assumption of any liability or fault of LuvBuckets for such incident.</p>
      <p style={styles.paragraph}>37. Beta Services</p>
      <p style={styles.paragraph}>From time to time, (i) we may invite you to try products or features related to our Service that are not generally available to all of our Members or the public or (ii) we may release products or features related to our Service that are identified in the App as beta, preview, pilot, limited release, or by a similar designation (collectively, “Beta Services”). Beta Services may be provided for evaluation or testing purposes only, and, therefore, may contain bugs or errors, and may not be as reliable as other features of the Service. We may discontinue Beta Services at any time in our sole discretion, and we may decide not to make Beta Services generally available. For the avoidance of doubt, Beta Services are a part of the Service, and, therefore, are subject to the terms of this Agreement, including, without limitation, Sections 21 and 22.</p>
      <p style={styles.paragraph}>38. Notices</p>
      <p style={styles.paragraph}>Any notice to you will be effective when we send it to the last email or physical address you gave us or when posted on our LuvBuckets Site. Any notice to us will be effective when delivered to us along with a copy to our legal counsel: Attn. Legal Department, LuvBuckets, ___________________________, or any addresses as we may later post on the LuvBuckets Site.</p>
      <p style={styles.paragraph}>39. Entire Agreement</p>
      <p style={{...styles.paragraph}}>These Terms constitute the entire agreement between us in relation to its subject matter and supersede all prior agreements, representations, and understandings.</p>
    </div>
  );
  async function agree() {
    if(props.agreeCallback) await props.agreeCallback();
    if(props.setModalOpen) props.setModalOpen(false);
  } 

  function disagree() {
    if(props.disagreeCallback) props.disagreeCallback();
    if(props.setModalOpen) props.setModalOpen(false);
  }
}

function PrivacyPolicy() {
  return (
  <Link to={"privacyPolicy"} style={styles.privacyPolicy} onClick={routeToPrivacyPolicy}>
    Privacy Policy
  </Link>);

  function routeToPrivacyPolicy() {
    //Linking.openURL(apiWebPrefix + '/privacyPolicy');
  }
}

const styles = {
  container: {
    position: "absolute",
    width: "100%",
    top: 0,
    left: 0,
    backgroundColor: "#333333",
    paddingLeft: "10%",
    paddingRight: "10%",
    paddingTop: "10%"
  },
  paragraph: {
    fontSize: 20,
    marginTop: 20,
    marginBottom: 20,
    color: "#FFFFFF"
  },
  subText: {
    fontSize: 20,
    marginTop: 20,
    marginLeft: 40,
    color: "#FFFFFF"
  },
  header: {
    fontSize: 30,
    color: "#FFFFFF",
    marginBottom: 20,
    alignSelf: "center"
  },
  buttonsWrapper: {
    position: "relative",
    width: "100%",
    height: 30,
    bottom: 0
  },
  privacyPolicy: {
    fontSize: 20,
    color: "#007C89",
    margin: 0
  }
}