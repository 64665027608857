import { Folder } from "../services/FolderService";

export interface SelectBucketAction {
  type: string;
  bucket: Folder;
}

export const SET_SELECTED_BUCKET = 'SET_SELECTED_BUCKET';
export const CLEAR_SELECTED_BUCKET = 'CLEAR_SELECTED_BUCKET';

export function setSelectedBucket(bucket: Folder) {
  return {
    type: SET_SELECTED_BUCKET,
    bucket
  }
}

export function clearSelectedBucket() {
  return {
    type: CLEAR_SELECTED_BUCKET 
  }
}