import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect, useHistory } from 'react-router-dom';
import { Home } from './components/Home/Home';
import { RegisterForm } from './components/RegisterForm/RegisterForm';
import { LoginForm } from './components/LoginForm/LoginForm';
import { ForgotPasswordForm } from './components/ForgotPasswordForm/ForgotPasswordForm';
import { PrivateRoute, PublicRoute } from './helpers/Routes';
import { ResetPasswordForm } from './components/ResetPasswordForm/ResetPasswordForm';
import { AppHeader } from './components/Navigation/AppHeader';
import styles from './App.module.scss';
import { UploadsView } from './components/Uploads/UploadsView';
import { RecipientsView } from './components/Recipients/RecipientsView';
import { RecipientEditForm } from './components/Recipients/RecipientsEditForm';
import { RecipientAddForm } from './components/Recipients/RecipientsAddForm';
import { MagicLink } from './components/MagicLink';
import { VideoView } from './components/Video/VideoView';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { State } from './store';
import { getUserInfo } from './services/AuthService'
import { ThemeProvider, createMuiTheme } from '@material-ui/core';
import { updateUser, logoutUser } from './actions';
import { DownloadExpoView } from './components/DownloadExpo/DownloadExpoView';
import { Sidebar } from './components/Navigation/Sidebar';
import { PrivacyPolicyView } from './components/PrivacyPolicy/PrivacyPolicyView';
import BucketView from './components/Buckets/BucketView';
import BucketsView from './components/Buckets/BucketsView';
import { AboutUsView } from './components/About/About';
import { LandingView } from './components/Landing/LandingView';
import { TermsOfUseView } from './components/TermsOfUse/TermsOfUseView';
import { MemoryAssetsView } from './components/Memories/MemoryAssets';
import { ViewImage } from './components/Image/ViewImage';
import { GivingView } from './components/Giving/GivingView';
import { TutorialView } from './components/Tutorial/TutorialView';
import { ViewJournal } from './components/Journal/JournalView';


export function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return null;
}

enum AuthState {
  IDLE = 'idle',
  FETCHING = 'fetching',
  COMPLETE = 'complete'
}

export interface AppProps {
  styles?: { readonly [key: string]: string };
}

const App: React.FC = () => {
  const props: AppProps = {};
  const dispatch = useDispatch();
  const [authState, setAuthState] = useState(AuthState.IDLE);
  const user = useSelector((state: State) => state.user, shallowEqual);
  const theme = useSelector((state: State) => state.theme, shallowEqual);
  const sidebarExpanded = useSelector((state: State) => state.sidebarExpanded, shallowEqual);

  useEffect(() => {
    document.body.style.backgroundColor = theme.mainBody.backgroundColor;
    (async() => {
      //@ts-ignore
      // if (user && user?.id > 0) {
      //   await getUserInfo(user.tokens[user.tokens.length - 1].token)
      //     .then(json => {
      //       if (!json.errors) {
      //         dispatch(updateUser(json.data.userInfo));
      //         //setAuthState(AuthState.COMPLETE);
      //       }
      //       else {
      //         dispatch(logoutUser());
      //         //setAuthState(AuthState.COMPLETE);
      //       }
      //     })
      //     .catch(e => {
      //       dispatch(logoutUser());
      //       //setAuthState(AuthState.COMPLETE);
      //     })
      // }
      // else {
      //   dispatch(logoutUser());
      //   //setAuthState(AuthState.COMPLETE);
      // }
    })()
  }, [])

  const providedTheme = createMuiTheme({
    palette: {
      ...theme.COLORS
    }
  })

  return (
    <ThemeProvider theme={providedTheme}>
      <Router>
      <Sidebar />
        <div className={`${styles.appWrapper} ${sidebarExpanded ? styles.appWrapperActive : ''}`} style={{ overflow: sidebarExpanded ? 'hidden': 'hidden'}}>
          <AppHeader />
          <div className={styles.main} style={theme.mainBody}>
            <Switch>
              <PublicRoute path="/privacyPolicy">
                <PrivacyPolicyView />
              </PublicRoute>
              <PublicRoute path="/magiclink/:token">
                <MagicLink />
              </PublicRoute>
              <PublicRoute path="/forgotPassword">
                <ForgotPasswordForm />
              </PublicRoute>
              <PrivateRoute path="/resetPassword">
                <ResetPasswordForm />
              </PrivateRoute>
              <PrivateRoute path="/buckets">
                <BucketsView />
              </PrivateRoute>
              <PrivateRoute path="/bucket">
                <BucketView />
              </PrivateRoute>
              <PrivateRoute path="/memoryAssets">
                <MemoryAssetsView />
              </PrivateRoute>
              <PrivateRoute path="/viewVideo">
                <VideoView />
              </PrivateRoute>
              <PrivateRoute path="/viewImage">
                <ViewImage />
              </PrivateRoute>
              <PrivateRoute path="/viewJournal">
                <ViewJournal />
              </PrivateRoute>
              <PublicRoute path="/ourStory">
                <AboutUsView />
              </PublicRoute>
              <PublicRoute path="/tutorial">
                <TutorialView />
              </PublicRoute>
              <PublicRoute path="/giving">
                <GivingView />
              </PublicRoute>
              <PublicRoute path="/termsOfUse">
                <TermsOfUseView />
              </PublicRoute>
              <PublicRoute path="/login">
                <LoginForm />
              </PublicRoute>
              <PublicRoute path="/">
                <LandingView />
              </PublicRoute>
            </Switch>
          </div>
        </div>
      </Router>
    </ThemeProvider>
  );
};

export default App;
