import { UPDATE_PROGRESS } from '../actions/progress';

export interface ProgressAction {
  type: string;
  progress?: number;
}

const initState = 0;

export function progressReducer(state = initState, action: ProgressAction) {
  switch (action.type) {
    case UPDATE_PROGRESS:
      return action.progress;
    default:
      return state;
  }
}
