import { TOGGLE_SIDEBAR, CLOSE_SIDEBAR } from "../actions/nav";

export interface NavAction {
  type: string;
}

const initState = false;

export function navReducer(state = initState, action: NavAction) {
  switch (action.type) {
    case TOGGLE_SIDEBAR:
      return !state;
    case CLOSE_SIDEBAR:
      return false;
    default:
      return state;
  }
}
