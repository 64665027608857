import React from 'react';
import { get } from "object-path";
import { State } from "../../store";
import { useSelector, shallowEqual } from "react-redux";
import formStyles from '../FormElems/FormElems.module.scss';

interface Props {
  placeholder: string;
  value: any;
  type: string;
  touched: any;
  errors: any;
  path: any;
  handleChange: any;
  onKeyUp: any;
  id: any
  secureTextEntry?: any;
}

export function InputElem(props: Props) {

  const placeholder = props.placeholder || '';

  const touched = get(props.touched, props.path);
  const errored = get(props.errors, [props.path]);
  const showErrorText = errored && touched;
  const theme = useSelector((state: State) => state.theme, shallowEqual);

  const type = props.type || "text";

  const onKeyUp = props.onKeyUp || (()=>{});

  return (<input
    className={formStyles.textField}
    type={type}
    id={props.id}
    style={{ borderColor: showErrorText ? theme.forms?.formErrorText?.color : '#C4C4C4' }}
    placeholder={placeholder}
    value={props.value}
    onKeyUp={onKeyUp}
    onChange={props.handleChange}
  />);
}