import { ProgressAction } from '../reducers';

export const UPDATE_PROGRESS = 'UPDATE_PROGRESS';

export const updateProgress = (progress: number): ProgressAction => {
  return {
    type: UPDATE_PROGRESS,
    progress,
  };
};
