import { get } from 'object-path';
import { getToken } from './AuthService';

import { store } from '../store';
import { updateProgress } from '../actions/progress';
import { hideLoadingSymbol, maxRetriesReached, showLoadingSymbol } from '../actions/request';

const currentGetUrl = '';

const url = process.env.REACT_APP_API_URL || '';

export const getUrl = () => {
  return currentGetUrl;
};

export const uploadFile = (uri: string) => {
  /* if (uri && uri.length > 0) {
    return getFile(uri)
      .then(async (data) => {
        store.dispatch(updateProgress(0.2));
        return await uploadToS3Bucket(data)
          .then(async (getUrl) => {
            store.dispatch(updateProgress(0.4));
            await updateAssetStatus(data);
            store.dispatch(updateProgress(0.6));
          })
          .catch((err) => {
            console.log('\n ERR_S3:' + JSON.stringify(err, null, 2) + '\n');
          });
      })
      .finally(async () => {
        await deleteFile(uri);
        store.dispatch(updateProgress(1));
      });
  } else {
    return Promise.resolve();
  } */
};

export const deleteFile = (uri: string) => {
  /* if (uri && uri.length > 0) {
    return FileSystem.deleteAsync(uri)
      .then(() => {
        console.log('File at path: \n' + uri + '\n' + 'has been deleted.');
      })
      .catch((err) => {
        console.log('Error with deleting file: \n' + err);
      });
  } else {
    return Promise.resolve();
  } */
};

const getSignedUrl = (fileData: any) => {
  /* const user = store.getState().user;
  const token = get(user, 'tokens.0.token');

  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
    body: JSON.stringify({
      query: `mutation CreateAsset($asset:AssetInput!) {
                  createAsset(asset:$asset) {
                      id
                      title
                      fileName
                      mimeType
                      shortened
                      path
                      assetType
                      signedUrl
                  }
                }
            `,
      variables: {
        asset: {
          fileName: fileData.name,
          mimeType: fileData.type,
        },
      },
    }),
  })
    .then((graphqlres) => graphqlres.json())
    .then((res) => res)
    .catch((err) => {
      console.log('\n ERR:' + JSON.stringify(err, null, 2) + '\n');
    }); */
};

const getFile = (uri: string) => {
  /* return fetch(uri).then(async (res) => {
    const blob = await res.blob();
    const fileData = JSON.parse(JSON.stringify(res._bodyInit._data));
    const assetData = await getSignedUrl(fileData);

    return { fileData: fileData, blob: blob, assetData: assetData.data.createAsset };
  }); */
};

const uploadToS3Bucket = (data: any) => {
  /* console.log('URL', data.assetData.signedUrl);
  const urlS3 = data.assetData.signedUrl;
  currentGetUrl = urlS3;
  return fetch(urlS3, {
    method: 'PUT',
    headers: {
      'Content-Type': data.fileData.type,
      'Content-Length': data.fileData.size,
    },
    body: data.blob,
  })
  .then(res => res.json())
  .then((res) => {
    console.log('S3 RES', res);
  }) */
};

const updateAssetStatus = (data: any) => {
  /* const user = store.getState().user;
  const token = get(user, 'tokens.0.token');
  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
    body: JSON.stringify({
      query: `mutation updateAssetStatus($asset:AssetInput!) {
                updateAssetStatus(asset:$asset) {
                    id
                    userId
                    title
                    status
                    path
                    location
                    fileName
                    assetType
                    mimeType
                      user {
                        id
                        first
                        last
                      }
                }
              }
            `,
      variables: {
        asset: {
          id: data.assetData.id,
          status: 'complete',
          signedUrl: currentGetUrl,
        },
      },
    }),
  })
    .then((graphqlres) => graphqlres.json())
    .then((res) => res)
    .catch((err) => {
      console.log('updateAssetStatus ERROR: ', JSON.stringify(err, null, 2));
    }); */
};

export async function getTextFromS3(signedUrl: string, filename: string) {
  let retries = 0;
  let keepLoadingSymbol = false;
  const maxRetries = 3;

  return new Promise(async(resolve) => {
    await loop(resolve);
  });

  async function loop(resolve: any) {
    if(retries < maxRetries) {
      await retry(resolve);
    }

    if(retries === maxRetries) {
      stopRetrying(resolve);
    }
  }

  async function stopRetrying(resolve: any) {
    store.dispatch(maxRetriesReached(true));
    keepLoadingSymbol = false;
    stop(resolve);
  }

  function stop(resolve: any, res: any = null) {
    if(!keepLoadingSymbol || res?.errors) store.dispatch(hideLoadingSymbol());
    retries = 0;
    return resolve(res);
  }

  async function retry(resolve: any) {
    retries++;
    await requestMain()
    .then(async (res: any) => {
      stop(resolve, res);
    }).catch(async(err: Error | any) => {
      await loop(resolve);
    })
  }

  async function requestMain() {
    store.dispatch(showLoadingSymbol());
    return new Promise(promiseMain);

    function promiseMain(resolve: any, reject: any) {
      fetch(signedUrl, {
        method: 'GET',
        headers: {
          'Content-Type': 'text/plain'
        }
      }).then(getText);

      async function getText(res: any) {
        const blob = await res.blob();
        const text = await blob.text();
        resolve(text);
      }
    }
  }
}