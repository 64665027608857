import { resolve } from 'path';
import { get } from 'object-path';
import { store } from '../store';
import { logoutUser } from '../actions';
import { hideLoadingSymbol, showLoadingSymbol, maxRetriesReached } from '../actions/request';

const apiUrl = process.env.REACT_APP_API_URL || '';

const getAuthToken = () => {
  const user = (store.getState().user || {});
  if(!user.tokens?.length) return "";
  const tokenIndex = user.tokens?.length - 1 || 0;
  return user.tokens[tokenIndex].token;
} 

let keepLoadingSymbol = false;

export function toggleKeepLoadingSymbolNextCall(keep?: boolean) {
  keepLoadingSymbol = typeof keep == 'boolean' ? keep : !keepLoadingSymbol;
}

export function logKeepLoadingSymbol() {

}

export class apiRequest {
  retries = 0;

  maxRetries = 2;

  promise: Promise<any> | null = null;

  /*
   * requestProps: req props passed to fetch
   * useAuth: if true will add auth header and handle expired token
   */
  init = async (requestProps: any, useAuth = false) => {
    const self = this;

    if (useAuth) {
      requestProps.headers.Authorization = `Bearer ${getAuthToken()}`;
    }

    return new Promise(async (resolve) => {
      loop(resolve);
    });

    async function loop(resolve: any) {
      if (self.retries < self.maxRetries) {
        await retry(resolve);
      }

      if (self.retries === self.maxRetries) {
        stopRetrying(resolve);
      }
    }

    async function stopRetrying(resolve: any) {
      store.dispatch(maxRetriesReached(true));
      stop(resolve);
    }

    async function retry(resolve: any) {
      self.retries++;
      await requestMain()
        .then((res) => {
          /* if(useAuth && res.status == 403){
          store.dispatch(logoutUser());
          stopRetrying(resolve);
        } */

          return res.json();
        })
        .then((res: any) => {
          // if auth check token valid
          if (useAuth) {
            const err = get(res, 'errors.0.message');
            const missingTokenMessage = 'An access token is required to access this data';
            const invalidTokenMessage = 'The access token is expired need to sign in again';

            if(err) {
              if (err.indexOf(missingTokenMessage) > -1 || err.indexOf(invalidTokenMessage) > -1) {
                store.dispatch(logoutUser());
                keepLoadingSymbol = false;
                stop(resolve, res);
                return;
              }
            }
          }
          // check valid res
          if (res.data || res.errors) {
            stop(resolve, res);
            store.dispatch(maxRetriesReached(false));
          } else {
            loop(resolve);
          }
        })
        .catch((err: Error | any) => {
          stopRetrying(resolve);
        });
    }

    function stop(resolve: any, res: any = null) {
      if (!keepLoadingSymbol || res?.errors) store.dispatch(hideLoadingSymbol());
      self.retries = 0;
      resolve(res);
    }

    async function requestMain() {
      store.dispatch(showLoadingSymbol());
      return fetch(apiUrl, requestProps);
    }
  };
}
