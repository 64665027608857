import React, { useEffect } from 'react';

import { State } from '../../store';
import { useSelector, shallowEqual } from 'react-redux';


export function LogoSVG(props: any) {

  const theme = useSelector((state: State) => state.theme, shallowEqual);

  const onClick = props.onClick || (()=>{});

  const largePersonColor = props.inNav ? theme.mainBody?.backgroundColor : theme.appColors?.secondary?.color;

  const className = props.className || '';

  const smallPersonColor = props.inNav ? theme.appColors?.secondary?.color : theme.appColors?.primary?.color;

  useEffect(() => {

  }, [])

  return (
    <div onClick={onClick} style={props.style} className={className}>
      <svg style={{position: "absolute", top: 0, left: 0, width: 'inherit', height: 'inherit'}} width="100%" height="100%"  viewBox="0 0 120 203">
        <g clipPath="url(#clip0)">
          <path d="M120 122.676V149.245C120 185.085 101.795 203.002 60 203.002C18.2052 203.002 0 185.085 0 149.245V113.784L0.0108398 86.395C0.0108398 79.8351 2.19481 73.2956 7.08721 68.8099C5.76001 70.1891 57.4272 114.711 63.7182 103.296C65.3164 100.329 66.5792 97.1947 67.4837 93.9508C73.0217 74.6909 82.3254 63.0184 96.0984 63.0184C113.008 63.0184 119.989 74.6699 119.989 86.395L120 122.676Z" fill="url(#paint0_linear)"/>
          <path d="M63.7199 103.291C57.4259 114.706 47.7521 117.613 38.8877 117.613C15.4025 117.613 3.10318 93.5285 3.10318 81.8381C3.10318 73.8972 5.76167 70.1841 7.08887 68.8049L7.12066 68.7732C10.9661 65.2613 16.4724 63.0134 23.898 63.0134C47.916 63.0134 47.1965 96.5729 63.3125 103.117C63.4487 103.178 63.5861 103.237 63.7199 103.291Z" fill="#3C4750"/>
          <path d="M111.18 16.7845C95.1689 -14.7611 55.8971 1.9679 67.4459 35.485C79.7603 71.2291 127.6 49.1379 111.18 16.7845Z" fill="#FFFF3C"/>
          <path d="M4.54394 42.874C9.44174 60.7559 30.404 53.4138 24.3674 38.8336C17.9432 23.3173 0.805342 29.2258 4.54394 42.874Z" fill="#3C4750"/>
        </g>
        <defs>
          <linearGradient id="paint0_linear" x1="120" y1="78" x2="60" y2="203.002" gradientUnits="userSpaceOnUse">
            <stop stopColor="#FFFF3C"/>
            <stop offset="0.473958" stopColor="#4DC8B7"/>
            <stop offset="1" stopColor="#C9F0F5"/>
          </linearGradient>
          <clipPath id="clip0">
            <rect width="120" height="203" fill="white"/>
          </clipPath>
        </defs>
      </svg>
    </div>
  );
}
