import React from 'react';

import { State } from '../../store';
import { useSelector, shallowEqual } from 'react-redux';


export function LogoTextSVG(props: any) {

  const theme = useSelector((state: State) => state.theme, shallowEqual);

  const onClick = props.onClick || (()=>{});

  const color = props.color || theme.appColors?.secondary?.color;

  return (
    <div onClick={onClick} style={props.style || {}} className={props.className || ""}>
      <svg style={{position: "absolute", top: 0, left: 0, width: '100%', height: '100%'}} width="100%" height="100%" viewBox="0 190 640 200">
        <g x="0" y="0" id="logotype">
          <path fill={color} d="M9,234.68c-4.97,0-9,4.03-9,9v82.18c0,4.97,4.03,9,9,9s9-4.03,9-9v-82.18C18,238.71,13.97,234.68,9,234.68z"/>
          <path fill={color} d="M571,246.68h-4v-3c0-3.95-2.57-7.43-6.34-8.6c-3.77-1.17-7.86,0.26-10.09,3.52c-1.05,1.53-5.05,6.58-9.56,9.59
            c-3.3,2.2-4.77,6.3-3.62,10.1c1.15,3.8,4.65,6.39,8.61,6.39h3v61.18c0,4.97,4.03,9,9,9s9-4.03,9-9v-61.18h4c4.97,0,9-4.03,9-9
            S575.97,246.68,571,246.68z"/>
          <path fill={color} d="M86,256.68c-4.97,0-9,4.03-9,9v40.18c0,10.13-6.82,11.5-13.5,11.5S50,316,50,305.86v-40.18c0-4.97-4.03-9-9-9
            s-9,4.03-9,9v40.18c0,18.2,12.07,29.5,31.5,29.5s31.5-11.3,31.5-29.5v-40.18C95,260.71,90.97,256.68,86,256.68z"/>
          <path fill={color} d="M315,256.68c-4.97,0-9,4.03-9,9v40.18c0,10.13-6.82,11.5-13.5,11.5S279,316,279,305.86v-40.18
            c0-4.97-4.03-9-9-9s-9,4.03-9,9v40.18c0,18.2,12.07,29.5,31.5,29.5s31.5-11.3,31.5-29.5v-40.18
            C324,260.71,319.97,256.68,315,256.68z"/>
          <path fill={color} d="M162.18,256.41c-4.93-0.62-9.44,2.87-10.06,7.81c-0.32,2.57-0.75,5.32-1.2,7.98
            c-0.45,2.68-0.93,5.36-1.44,8.03c-1.07,5.32-2.27,10.61-3.64,15.78c-1.34,5.17-2.99,10.24-4.9,14.84c-0.89,2.14-1.89,4.17-2.9,5.76
            c-0.93-1.52-1.84-3.48-2.66-5.5c-1.85-4.61-3.43-9.7-4.69-14.88c-1.29-5.19-2.41-10.49-3.39-15.83c-0.47-2.67-0.91-5.36-1.31-8.05
            c-0.41-2.67-0.79-5.42-1.07-8c-0.01-0.11-0.03-0.26-0.05-0.37c-0.75-4.92-5.34-8.29-10.26-7.55s-8.29,5.34-7.55,10.26l0.05,0.3
            c0.45,2.94,0.92,5.68,1.43,8.51c0.51,2.8,1.04,5.6,1.61,8.4c1.14,5.59,2.41,11.18,3.9,16.78c1.51,5.61,3.19,11.21,5.55,16.98
            c1.22,2.9,2.54,5.81,4.62,8.97c1.07,1.58,2.32,3.25,4.32,5.01c1.05,0.88,2.26,1.77,3.94,2.53c1.67,0.75,3.82,1.27,6.01,1.18
            c0.54-0.03,1.09-0.07,1.61-0.15c0.51-0.09,1.02-0.2,1.52-0.33c0.93-0.28,1.85-0.63,2.59-1.01c1.55-0.78,2.72-1.67,3.65-2.52
            c1.91-1.72,3.11-3.35,4.15-4.89c2.05-3.1,3.41-5.99,4.65-8.86c2.42-5.72,4.18-11.29,5.77-16.88c1.58-5.58,2.93-11.14,4.16-16.71
            c0.61-2.79,1.19-5.57,1.74-8.37c0.55-2.82,1.07-5.55,1.56-8.48l0.06-0.33c0.02-0.11,0.03-0.22,0.05-0.33
            C170.61,261.54,167.11,257.04,162.18,256.41z"/>
          <path fill={color} d="M244.07,271.88c-1.11-2.09-2.52-4.12-4.22-5.95c-0.85-0.92-1.76-1.79-2.72-2.6c-0.98-0.79-2.01-1.52-3.07-2.17
            c-2.12-1.32-4.37-2.32-6.63-2.98c-2.26-0.67-4.49-1.14-6.67-1.28c-2.18-0.17-4.29-0.16-6.33-0.02c-2.03,0.2-4.1,0.49-6.16,1.01
            c-3.53,0.84-7.07,2.36-10.27,4.55v-18.75c0-4.97-4.03-9-9-9s-9,4.03-9,9v82.18c0,4.97,4.03,9,9,9c3.75,0,6.96-2.3,8.32-5.56
            c3.37,2.45,7.16,4.14,10.95,5.04c2.06,0.52,4.12,0.81,6.16,1.01c2.04,0.14,4.15,0.15,6.33-0.02c2.18-0.14,4.41-0.61,6.67-1.28
            c2.26-0.66,4.51-1.67,6.63-2.98c1.06-0.65,2.09-1.38,3.07-2.17c0.97-0.81,1.87-1.68,2.72-2.6c1.7-1.83,3.11-3.87,4.22-5.95
            c2.23-4.18,3.41-8.45,4.07-12.49c0.66-4.07,0.87-7.95,0.85-11.75c0.01-3.8-0.2-7.68-0.85-11.75
            C247.48,280.33,246.31,276.05,244.07,271.88z M230.17,304.41c-0.5,2.57-1.41,4.79-2.46,6.52c-1.1,1.7-2.24,2.95-3.77,3.86
            c-0.77,0.46-1.62,0.85-2.57,1.23c-0.96,0.37-2.03,0.63-3.19,0.88c-1.16,0.24-2.42,0.39-3.73,0.47c-1.32,0.12-2.61,0.15-3.85,0.1
            c-2.47-0.14-4.76-0.45-6.67-1.25c-0.99-0.35-1.89-0.8-2.71-1.38c-0.82-0.58-1.64-1.24-2.34-2.11c-0.31-0.35-0.6-0.75-0.87-1.16
            v-30.9c0.28-0.41,0.56-0.81,0.87-1.16c0.7-0.86,1.52-1.53,2.34-2.11c0.82-0.58,1.73-1.04,2.71-1.38c1.91-0.8,4.2-1.11,6.67-1.25
            c1.24-0.05,2.53-0.02,3.85,0.1c1.31,0.08,2.57,0.23,3.73,0.47c1.16,0.25,2.24,0.51,3.19,0.88c0.95,0.38,1.8,0.77,2.57,1.23
            c1.53,0.92,2.67,2.16,3.77,3.86c1.05,1.72,1.96,3.94,2.46,6.52c0.54,2.55,0.77,5.39,0.83,8.3
            C230.94,299.02,230.72,301.86,230.17,304.41z"/>
          <path fill={color} d="M357.51,279.07c1.02-1.11,2.17-1.96,3.6-2.65c1.41-0.71,3.2-1.11,5.15-1.35c1.93-0.25,4.17-0.26,6.22-0.06
            c2.06,0.19,4,0.63,5.73,1.25c1.72,0.64,3.2,1.48,4.36,2.46c3.37,2.82,8.41,2.64,11.54-0.55c3.28-3.35,3.22-8.73-0.13-12.01
            l-0.42-0.41c-2.73-2.67-5.96-4.64-9.27-6.01c-3.31-1.39-6.72-2.17-10.07-2.55c-3.36-0.4-6.62-0.43-10.1-0.02
            c-3.45,0.41-7.07,1.18-10.61,2.84c-3.53,1.61-6.87,4.03-9.54,6.98c-2.73,2.94-4.54,6.35-5.93,9.68
            c-2.63,6.73-3.32,13.29-3.38,19.45c0.04,6.16,0.71,12.72,3.33,19.47c1.38,3.34,3.18,6.76,5.91,9.71c2.66,2.96,6.02,5.39,9.55,7.02
            c3.55,1.68,7.18,2.46,10.64,2.88c3.5,0.42,6.76,0.4,10.14,0.02c3.37-0.39,6.78-1.22,10.09-2.62c3.31-1.38,6.53-3.39,9.23-6.11
            l0.53-0.53c3.05-3.09,3.29-8.04,0.45-11.42c-3.02-3.58-8.37-4.04-11.96-1.02c-1.16,0.98-2.65,1.81-4.36,2.46
            c-1.73,0.62-3.67,1.06-5.73,1.25c-2.05,0.2-4.29,0.19-6.22-0.06c-1.94-0.25-3.74-0.65-5.15-1.35c-1.43-0.68-2.59-1.54-3.6-2.65
            c-1.04-1.06-1.82-2.6-2.53-4.3c-1.36-3.49-1.99-8-1.98-12.74c-0.02-4.75,0.62-9.25,1.98-12.74
            C355.69,281.68,356.47,280.14,357.51,279.07z"/>
          <path fill={color} d="M638.41,310.29c-0.03-0.62-0.05-1.23-0.1-1.85c-0.08-0.64-0.17-1.27-0.27-1.9c-0.46-2.55-1.39-5.17-2.79-7.51
            c-1.39-2.37-3.24-4.35-5.17-5.95c-0.97-0.83-1.98-1.47-2.99-2.13c-1.01-0.57-2.04-1.17-3.05-1.6c-2.04-0.96-4.04-1.59-6.02-2.13
            c-0.98-0.23-1.97-0.5-2.93-0.65l-1.45-0.26l-1.21-0.19l-4.74-0.75l-0.59-0.09l-0.45-0.08c-0.29-0.06-0.58-0.09-0.86-0.16
            c-0.57-0.13-1.13-0.26-1.66-0.42c-2.16-0.61-3.99-1.59-4.94-2.43c-0.47-0.42-0.67-0.76-0.8-1c-0.12-0.26-0.21-0.45-0.2-0.9
            c0-0.05-0.02-0.1-0.02-0.16l0.02-0.22c0.02-0.14-0.02-0.31,0.03-0.43c0.03-0.13,0.05-0.25,0.06-0.38c0.03-0.12,0.08-0.21,0.1-0.33
            c0.14-0.41,0.32-0.75,0.59-1.11c0.59-0.66,1.73-1.52,3.5-2.07c0.89-0.26,1.88-0.47,2.95-0.58c0.53-0.08,1.1-0.06,1.65-0.11
            c0.63-0.01,1.28-0.04,1.92-0.02c2.56,0.01,5.09,0.37,7.42,0.88c2.31,0.54,4.43,1.36,5.79,2.23l1.06,0.67
            c3.54,2.25,8.29,1.57,11.04-1.74c2.98-3.59,2.48-8.92-1.11-11.9c-3.85-3.19-7.83-4.89-11.7-6.07c-3.88-1.16-7.73-1.76-11.57-1.96
            c-0.96-0.06-1.91-0.07-2.88-0.08c-1.05,0.05-2.07,0.04-3.13,0.16c-2.11,0.18-4.26,0.56-6.44,1.19c-2.18,0.68-4.37,1.51-6.51,2.83
            c-2.12,1.3-4.17,2.96-5.9,5.04c-1.72,2.1-3.06,4.58-3.88,7.17c-0.19,0.65-0.38,1.3-0.53,1.95c-0.12,0.65-0.23,1.3-0.32,1.96
            c-0.11,0.66-0.1,1.28-0.15,1.93l-0.04,0.96c0,0.33,0.02,0.68,0.04,1.02c0.07,2.7,0.8,5.63,2.1,8.17c1.28,2.56,3.07,4.74,4.97,6.42
            c3.85,3.32,7.93,5.07,11.86,6.23c0.99,0.29,1.98,0.53,2.97,0.74c0.49,0.11,0.99,0.19,1.49,0.28l1.32,0.22l4.74,0.75l1.16,0.19
            l0.91,0.17c0.63,0.09,1.18,0.26,1.76,0.39c1.11,0.31,2.18,0.65,3.04,1.07c0.47,0.19,0.82,0.43,1.22,0.63
            c0.32,0.24,0.68,0.44,0.92,0.67c0.53,0.45,0.91,0.85,1.13,1.27c0.23,0.42,0.4,0.87,0.52,1.47c0.01,0.16,0.04,0.32,0.07,0.48
            l0.02,0.55c0.03,0.19-0.01,0.35-0.02,0.52c-0.02,0.16,0.01,0.35-0.03,0.49c-0.2,1.24-0.6,2.03-1.52,2.91
            c-0.45,0.44-1.11,0.83-1.81,1.24c-0.41,0.16-0.76,0.39-1.23,0.53l-0.67,0.24l-0.73,0.19c-0.47,0.16-1.03,0.21-1.54,0.33
            c-0.54,0.08-1.1,0.13-1.66,0.2l-1.76,0.07c-0.64,0.02-1.29,0.03-1.92,0.02c-5.12-0.05-9.93-1.42-13.9-3.67l-0.78-0.44
            c-3.73-2.09-8.52-1.08-11.05,2.49c-2.71,3.82-1.81,9.11,2,11.82c6.94,4.92,14.96,7.16,22.64,7.66c3.73,0.26,8,0.11,12.23-0.95
            l1.6-0.42c0.53-0.17,1.07-0.36,1.61-0.54c1.06-0.34,2.14-0.87,3.2-1.35c2.11-1.1,4.2-2.42,6.08-4.2c1.86-1.78,3.56-3.88,4.77-6.29
            c1.2-2.4,2.01-5,2.31-7.56c0.11-0.65,0.11-1.26,0.15-1.9C638.41,311.53,638.45,310.9,638.41,310.29z"/>
          <path fill={color} d="M532.15,267.93c-1.87-2.9-4.42-5.26-7.07-6.86c-2.66-1.61-5.39-2.59-8.04-3.23c-2.65-0.65-5.26-0.95-7.88-0.97
            c-2.57-0.02-5.07,0.16-7.64,0.63c-5.1,0.92-10.2,3.09-14.48,6.39c-4.29,3.29-7.63,7.67-9.85,12.29c-2.23,4.64-3.4,9.49-3.91,14.2
            c-0.26,2.34-0.33,4.74-0.29,6.94c0.03,2.21,0.16,4.47,0.44,6.76c0.56,4.58,1.62,9.39,3.98,14.16c1.18,2.37,2.69,4.71,4.57,6.83
            c1.86,2.12,4.09,4,6.51,5.46c4.86,2.98,10.17,4.26,15.04,4.67c2.46,0.21,4.8,0.21,7.2,0.06c2.4-0.16,4.83-0.49,7.27-1.08
            c4.86-1.15,9.86-3.29,14.19-6.93c3.11-2.61,3.96-7.17,1.82-10.77c-2.38-4.01-7.56-5.32-11.57-2.94l-1.24,0.74
            c-2.05,1.22-4.51,2.16-7.3,2.62c-1.38,0.28-2.85,0.43-4.35,0.47c-1.5,0.03-3.07,0-4.5-0.12c-2.89-0.26-5.33-0.99-7.13-2.13
            c-1.82-1.12-3.14-2.69-4.3-4.9c-1.03-2.02-1.75-4.6-2.16-7.43c1.78,0.55,3.58,1.04,5.38,1.41c2.1,0.45,4.21,0.72,6.32,0.94
            c0.5,0.06,1.11,0.1,1.71,0.14c0.59,0.03,1.19,0.04,1.79,0.05c1.2,0,2.4-0.06,3.61-0.18c2.42-0.25,4.86-0.74,7.26-1.58
            c2.39-0.85,4.75-2.01,6.98-3.54c2.22-1.54,4.33-3.47,6.05-5.82c1.73-2.34,3.1-5.09,3.9-8c0.42-1.45,0.66-2.94,0.82-4.42
            c0.15-1.49,0.13-2.94,0-4.49C535.04,274.2,534.03,270.84,532.15,267.93z M517.46,282.28c-0.11,1.77-0.94,3.6-2.63,5.38
            c-0.85,0.87-1.89,1.68-3.09,2.37c-1.18,0.71-2.51,1.33-3.93,1.79c-0.71,0.23-1.44,0.43-2.19,0.59c-0.37,0.08-0.75,0.15-1.13,0.21
            c-0.38,0.06-0.75,0.12-1.22,0.16c-1.8,0.21-3.58,0.26-5.37,0.37c-1.79,0.09-3.57,0.07-5.32-0.04c-0.49-0.04-0.96-0.09-1.44-0.14
            c0.02-0.23,0.04-0.46,0.06-0.69c0.33-3.04,1.06-5.88,2.22-8.29c1.16-2.41,2.72-4.38,4.62-5.85c1.91-1.46,4.18-2.47,6.76-2.95
            c1.27-0.23,2.68-0.35,4.01-0.32c1.28,0.05,2.57,0.25,3.7,0.57c1.13,0.32,2.08,0.8,2.72,1.27c0.65,0.47,1.03,0.85,1.26,1.24
            c0.25,0.38,0.47,0.82,0.7,1.61c0.09,0.39,0.17,0.89,0.22,1.36C517.47,281.38,517.5,281.84,517.46,282.28z"/>
          <path fill={color} d="M460.87,308.14c-3.21-4.87-7.78-9.62-13.26-12.63c-2.54-1.41-5.14-2.45-7.76-3.22
            c0.81-0.43,1.63-0.85,2.43-1.32c4.11-2.35,8.08-5.16,11.71-8.49c3.66-3.3,6.92-7.18,9.7-11.45c0.09-0.15,0.2-0.32,0.29-0.47
            c2.46-4.33,0.94-9.82-3.39-12.28c-4.33-2.46-9.82-0.94-12.28,3.39l-0.37,0.66c-1.71,3.01-3.76,5.86-6.24,8.41
            c-2.45,2.57-5.26,4.89-8.33,6.94c-2.92,1.97-6.09,3.67-9.37,5.14v-39.14c0-4.97-4.03-9-9-9s-9,4.03-9,9v82.18c0,4.97,4.03,9,9,9
            s9-4.03,9-9v-21.1c1.49,0.14,2.96,0.31,4.37,0.55c4.17,0.7,7.83,1.9,10.69,3.71c2.85,1.81,5.15,4.31,7.19,7.77
            c2.03,3.43,3.72,7.6,5.25,12l0.21,0.6c0.06,0.18,0.13,0.35,0.2,0.52c1.93,4.58,7.22,6.73,11.8,4.8s6.73-7.22,4.8-11.8
            C466.44,318.04,464.13,313.03,460.87,308.14z"/>
        </g>
      </svg>
    </div>
  );
}