import { SELECT_RECIPIENT } from '../actions/recipient';

export interface Recipient {
  first: string;
  last: string;
  email: string;
  startDate: Date;
  endDate: Date;
  index?: number;
}

export interface RecipientAction {
  type: string;
  recipient?: Recipient;
}

const initState = {};

export function recipientReducer(state = initState, action: RecipientAction) {
  switch (action.type) {
    case SELECT_RECIPIENT:
      return { ...state, ...action.recipient };
    default:
      return state;
  }
}
