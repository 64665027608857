import React, { useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { Folder, getRecievedFolders, getUserFolders } from "../../services/FolderService";
import { State } from "../../store";
import { LoadingOverlayElem } from "../Navigation/LoadingOverlay";
import { UploadHeader } from "../Uploads/UploadHeader";
import { BucketMemoriesLink, BucketReceivedMemoriesLink } from "./BucketLinks";
import styles from './BucketsView.module.scss';

export default function BucketsView() {
  const [userFolders, setUserFolders] = useState([]);

  const [receivedFolders, setReceivedFolders] = useState([]);
  
  const isLoading = useSelector((state: State) => state.request.isLoading);
  
  const [toggleUserBuckets, setToggleUserBuckets] = useState(true);

  const user = useSelector((state: State) => state.user, shallowEqual);

  const selectedBucketsBorderStyle = {
    color: "#4DC8B7",
    borderBottomWidth: 2,
    borderBottomStyle: "solid",
    borderColor: "#4DC8B7"
  } as React.CSSProperties;

  const fetchData = async () => {
    const folders = await getUserFolders();
    const receivedFolders = await getRecievedFolders();
    //console.log(folders + '\n \n \n' + receivedFolders);
    setUserFolders(folders);
    setReceivedFolders(receivedFolders);
  }

  useEffect(() => {
    (async () => {
      if(userFolders?.length && receivedFolders?.length) return;
      await fetchData();
    })()
  }, [])

  return (
    <div className={styles.mainContainer}>
      {!!isLoading ? <LoadingOverlayElem /> : <></>}
      <div className={styles.bucketButtonsWrapper}>
        <p onClick={toggleUserBucketsBool} className={styles.userBucketsText} style={(toggleUserBuckets ? selectedBucketsBorderStyle : {})}>My Buckets</p>
        <p onClick={toggleFriendsBucketsBool} className={styles.receivedBucketsText} style={(!toggleUserBuckets ? selectedBucketsBorderStyle : {})}>{user.first}'s Bucket</p>
      </div>
      <div className={styles.bucketsListWrapper}>
        <div className={styles.bucketsListInner}>
          {
            userFolders?.length && toggleUserBuckets ? userFolders.map((folder: Folder) => <BucketMemoriesLink folder={folder} key={folder.id} />) : <></>
          }
          {
            receivedFolders?.length && !toggleUserBuckets ? receivedFolders.map((folder: Folder) => <BucketReceivedMemoriesLink folder={folder} key={folder.id} />) : <></>
          }
        </div>
      </div>
    </div>
  );

  function toggleUserBucketsBool() {
    setToggleUserBuckets(true);
  }

  function toggleFriendsBucketsBool() {
    setToggleUserBuckets(false);
  }
}