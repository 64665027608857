import { User, UserAction } from '../reducers';

export const UPDATE_USER = 'UPDATE_USER';
export const LOGOUT_USER = 'LOGOUT_USER';
export const LOGIN_USER = 'LOGIN_USER';

export const updateUser = (user: User): UserAction => {
  return {
    type: UPDATE_USER,
    user,
  };
};

export const logoutUser = (): UserAction => {
  return {
    type: LOGOUT_USER,
  };
};
