import React, { Fragment, useState } from 'react';
import { Formik, FormikHelpers, Form } from 'formik';
import { object, string, date, ref } from 'yup';
import { get } from 'object-path';
import { useDispatch, useSelector } from 'react-redux';
import { FormControl } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { FormErrorText } from '../FormErrorText';
import { State } from '../../store';
import { forgotPassword, resetPassword } from '../../services/AuthService';
import { PasswordField } from '../PasswordField';
import { LoadingOverlayElem } from '../Navigation/LoadingOverlay';
import { setOnFinishCallback, showMessage } from '../../services/LoadingService';
import styles from './ResetPasswordForm.module.scss';
import { hideLoadingSymbol } from '../../actions/request';
import { StyledButton } from '../FormElems/StyledButton';

export const validationSchema = object().shape({
  password: string().min(8, 'Password must be at least 8 characters long.').required('Please enter a password'),
  confirmPassword: string().oneOf([ref('password'), undefined], 'Passwords must match').required('Please confirm your password')
});

interface ResetPassForm {
  password: string;
  confirmPassword: string;
}

export const ResetPasswordForm = () => {
  const initialValues: ResetPassForm = {
    password: '',
    confirmPassword: ''
  };

  const history = useHistory();

  const isLoading = useSelector((state: State) => state.request.isLoading);

  const dispatch = useDispatch();

  const handleOnSubmit = async (values: ResetPassForm, helpers: FormikHelpers<ResetPassForm>) => {

    await resetPassword(values.password)
      .then((json: any) => {
        helpers.setSubmitting(false);
        const validationErrors = get(json, 'errors.0.extensions.exception.validation', false);

        if (!validationErrors) {
          //@ts-ignore
          setOnFinishCallback(() => {
            history.push('/');
          });
          showMessage();
        } else {
          Object.keys(validationErrors).map((key: string) => {
            helpers.setFieldError(key, validationErrors[key][0]);
          });
          dispatch(hideLoadingSymbol());
        }
      })
      .catch(() => {
        if(helpers) {
          helpers.setSubmitting(false);
        }
      });
  };

  return (
    <>
      {isLoading && <LoadingOverlayElem message={"Thank You, your password was reset successfully."} />}
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnChange
        validateOnBlur
        enableReinitialize
        onSubmit={handleOnSubmit}
      >
        {({ errors, touched, values, handleChange, handleSubmit, setFieldTouched, setFieldValue, isSubmitting }) => {
          const isTouched = !!Object.keys(touched).length;
          const isErrored = !!Object.keys(errors).length;
          const disabled: boolean = !isTouched || isErrored;

          return (
            <Form className={styles.form}>
              <FormControl margin="normal" fullWidth>
                <PasswordField
                  value={values.password}
                  label="Your Password"
                  id="password"
                  handleChange={handleChange}
                  onKeyUp={(e: any) => setFieldTouched('password', true, false)}
                  errors={errors}
                  touched={touched}
                />
                <PasswordField
                  value={values.confirmPassword}
                  label="Confirm Password"
                  id="confirmPassword"
                  handleChange={handleChange}
                  onKeyUp={(e: any) => setFieldTouched('confirmPassword', true, false)}
                  errors={errors}
                  path={"confirmPassword"}
                  touched={touched}
                />
              </FormControl>
              <StyledButton text={"Reset Password"} isSubmitting={isSubmitting} disabled={disabled} onClick={handleSubmit}/>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};
