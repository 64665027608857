import React, { Fragment, useState } from 'react';
import { Formik, FormikHelpers, Form } from 'formik';
import { object, string, date } from 'yup';
import { get } from 'object-path';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { FormControl, TextField } from '@material-ui/core';
import { FormErrorText } from '../FormErrorText';
import { State } from '../../store';
import { forgotPassword } from '../../services/AuthService';
import { ThankYou } from '../ThankYou/ThankYou';
import { LoadingOverlayElem } from '../Navigation/LoadingOverlay';
import { setOnFinishCallback, showMessage } from '../../services/LoadingService';
import styles from './ForgotPasswordForm.module.scss';
import { hideLoadingSymbol } from '../../actions/request';
import { StyledButton } from '../FormElems/StyledButton';
import formStyles from '../FormElems/FormElems.module.scss';
import { InputElem } from '../FormElems/InputElem';

export const validationSchema = object().shape({
  email: string().required('Please enter a valid email address.').email('Please enter a valid email address.'),
});

interface ForgotPassForm {
  email: string;
}

export function ForgotPasswordForm() {
  const [wasSuccessful, setWasSuccessful] = useState(false);
  const initialValues: ForgotPassForm = {
    email: '',
  };

  const isLoading = useSelector((state: State) => state.request.isLoading);

  const theme = useSelector((state: State) => state.theme, shallowEqual);

  const dispatch = useDispatch();

  const handleOnSubmit = async (values: ForgotPassForm, helpers: FormikHelpers<ForgotPassForm>) => {
    await forgotPassword(values.email)
      .then((json: any) => {
        helpers.setSubmitting(false);
        const validationErrors = get(json, 'errors.0.extensions.exception.validation', false);

        if (!validationErrors) {
          setOnFinishCallback(() => {
            setWasSuccessful(true);
          });
          showMessage();
        } else {
          Object.keys(validationErrors).map((key: string) => {
            helpers.setFieldError(key, validationErrors[key][0]);
          });
          dispatch(hideLoadingSymbol());
        }
      })
      .catch(() => {
        helpers.setSubmitting(false);
      });
  };

  return (
    <>
      <h1 style={{ ...theme.appText?.header }}>Forgot Password</h1>
      <p style={{ ...theme.appText?.text }}>Enter your email address to receive a magic link.</p>
      {isLoading && <LoadingOverlayElem message={"Thank You, a password reset email was sent."} />}
      {!wasSuccessful && (
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          validateOnChange
          validateOnBlur
          enableReinitialize
          onSubmit={handleOnSubmit}
        >
          {({ errors, touched, values, handleChange, handleSubmit, setFieldTouched, setFieldValue, isSubmitting }) => {
            const isTouched = !!Object.keys(touched).length;
            const isErrored = !!Object.keys(errors).length;
            const disabled: boolean = !isTouched || isErrored;

            return (
              <Form className={styles.form}>
                <FormControl margin="normal" fullWidth>
                  <InputElem
                    id="email"
                    path="email"
                    value={values.email}
                    placeholder="Email Address"
                    handleChange={handleChange}
                    onKeyUp={(e: any) => setFieldTouched('email', true, false)}
                    errors={errors} 
                    touched={touched}
                    type={"text"}
                  />
                  <FormErrorText errors={errors} touched={touched} path="email" />
                </FormControl>

                <StyledButton disabled={disabled} text={"Send Reset Password Email"} isSubmitting={isSubmitting} onClick={handleSubmit} />
              </Form>
            );
          }}
        </Formik>
      )}
    </>
  );
}
