// import { UPDATE_PROGRESS } from '../actions/progress';

import { MAX_TRIES_REACHED, START_LOADING, STOP_LOADING } from '../actions/request';

export interface RequestAction {
  type: string;
  maxTriesReached?: boolean;
  isLoading?: boolean;
}

export interface AppRequestStatus {
  maxTriesReached: boolean;
  isLoading: boolean;
}

const initState = {
  maxTriesReached: false,
  isLoading: false,
};

export function requestReducer(state = initState, action: RequestAction) {
  switch (action.type) {
    case MAX_TRIES_REACHED:
      return { ...state, maxTriesReached: action.maxTriesReached };
    case START_LOADING:
      return { ...state, isLoading: true };
    case STOP_LOADING:
      return { ...state, isLoading: false };
    default:
      return state;
  }
}
