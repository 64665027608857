import React, { useState, useEffect } from 'react';
import { Player, ControlBar, BigPlayButton } from 'video-react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { State } from '../../store';
import '../../../node_modules/video-react/dist/video-react.css';
import styles from './VideoView.module.scss';
import { useHistory, useLocation } from 'react-router-dom';
import { VideoControls } from './VideoControls';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

export function VideoView() {
  const [playerInstance, setPlayerInstance] = useState();

  const location: any = useLocation();

  const history = useHistory();

  const uri = location?.state?.uri;

  const isMov = uri ? (uri.indexOf('.mov') > -1) : false;

  useEffect(() => {
    if(!playerInstance) return;
    if(!uri) history.push('/buckets');
    //@ts-ignore
    playerInstance.video.load();
  }, [playerInstance]);

  return (
    <div className={styles.videoContainer}>
      <FontAwesomeIcon
        className={styles.closeIcon}
        onClick={goBack}
        icon={faTimes}
      />
      <Player
        ref={(player: any) => {
          setPlayerInstance(player || {});
        }}
        fluid={false}
        height="100%"
        src={uri}
      >
        <ControlBar disableCompletely={true} disableDefaultControls={true} />
          <BigPlayButton position={"center"} className={styles.hideButton}/>
        {playerInstance?.video && <VideoControls videoControls={playerInstance} />}
      </Player>
    </div>
  );

  function goBack() {
    history.goBack();
  }
}
