import React from 'react';

import { State } from "../../store";
import { useSelector, shallowEqual } from "react-redux";


export function PlayVideoSVG(props: any) {

  const theme = useSelector((state: State) => state.theme, shallowEqual);
  
  const onClick = props.onClick || (()=>{});

  const className = props.className || '';

  const fill = props.fill || theme.appColors?.primary?.color;

  return (
    <div onClick={onClick} style={props.style || {}} className={className}>
      <svg style={{position: "absolute", top: 0, left: 0, width: 'inherit', height: 'inherit'}} width="100%" height="100%">
        <g>
          <path fill={fill} d="M28.76,19.01l-13.71-7.92c-0.76-0.44-1.71,0.11-1.71,0.99v15.84c0,0.88,0.95,1.43,1.71,0.99l13.71-7.92
                C29.52,20.55,29.52,19.45,28.76,19.01z"/>
        </g>
      </svg>
    </div>
  );
}
