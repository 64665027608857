import { Recipient, UploadsAction } from '../reducers';

export const SELECT_RECIPIENT = 'SELECT_RECIPIENT';

export const selectRecipient = (recipient: Recipient): UploadsAction => {
  return {
    type: SELECT_RECIPIENT,
    recipient,
  };
};
