let showMessageCb: any | void;
let onFinishCb: any | void;

export function hasShowMessageCb() {
  return !!showMessageCb;
}

export function setShowMessageCallback(showMessageFunc: any | void) {
  showMessageCb = showMessageFunc;
}

export function setOnFinishCallback(onFinishFunc: any | void) {
  onFinishCb = onFinishFunc;
}

export function showMessage() {
  if(!showMessageCb) throw new Error('No show message callback was passed.');
  showMessageCb(onFinishCb);
}