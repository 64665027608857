import { faLock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { setSelectedBucket } from "../../actions/SelectedBucket";
import { Folder } from "../../services/FolderService";
import { State } from "../../store";
import { BucketTealIconSVG } from "../svgs/BucketTealIconSVG";
import styles from './BucketsView.module.scss';

interface BucketLinkProps {
  folder: Folder;
}

export function BucketMemoriesLink(props: BucketLinkProps) {
  const folder: Folder = props.folder;

  const theme = useSelector((state: State) => state.theme, shallowEqual);

  const [imageURI, setImageURI] = useState(folder.signedUrlProfileImageThumb);

  const history = useHistory();

  const dispatch = useDispatch();

  useEffect(() => {
    if(folder?.signedUrlProfileImageThumb?.length) {
      setImageURI(folder.signedUrlProfileImageThumb);
    } 
    if(!imageURI?.length) {
      getThumbFromMemories();
    }
  }, [folder]);

  return (
    <div className={styles.bucketWrapper} style={{ ...theme.app?.border }} onClick={navToBucket}>
        <div className={styles.bucketImageWrapper}>
          <img alt="" src={imageURI} />
        </div>
        <div className={styles.bucketTitle}>{folder.title}</div>
        <BucketTealIconSVG className={styles.tealBucketIcon} />
        {folder?.receivingUsers?.length ? <div className={styles.folderAssignedUsersTextWrapper}>
            {folder?.receivingUsers?.length <= 2 ? 
              <p className={styles.folderAssignedUsersText}>
                {folder.receivingUsers[0].first}{!!folder.receivingUsers[1] ? `, and ${folder.receivingUsers[1].first}` : ''}
              </p>
            :
              <p className={styles.folderAssignedUsersText}>
                {folder.receivingUsers[0].first}, {folder.receivingUsers[1].first} {`+${folder?.receivingUsers?.length - 2} others`}
              </p>
            }
          </div> : <></>}
    </div>);

  function navToBucket() {
    dispatch(setSelectedBucket(folder));
    history.push('/bucket');
  }

  function getThumbFromMemories() {
    if(folder?.memories?.length) {
      for(var i=0; i < folder?.memories.length; i++) {
        const memory = folder.memories[i];
        if(memory?.assets?.length) {
          const assets = memory.assets.filter((asset) => {
            return asset.signedUrlThumb;
          });
          if(assets.length) {
            //@ts-ignore
            setImageURI(assets[0].signedUrlThumb);
            break;
          }
        }
      }
    }
  }
}


export function BucketReceivedMemoriesLink(props: BucketLinkProps) {
  const folder: Folder = props.folder;

  const theme = useSelector((state: State) => state.theme, shallowEqual);

  const now = new Date();
  
  const [imageURI, setImageURI] = useState(folder.signedUrlProfileImageThumb);

  const startDatePassed = !!folder?.startDate ? now.getTime() >= new Date(folder.startDate).getTime() : true;

  const endDateNotPassed = !!folder?.endDate ? new Date(folder.endDate).getTime() > now.getTime() : true;

  const isUnlocked = (!!folder?.startDate ? (startDatePassed && endDateNotPassed) : true);

  const history = useHistory();
  
    const dispatch = useDispatch();


  useEffect(() => {
    if(folder?.signedUrlProfileImageThumb?.length) {
      setImageURI(folder.signedUrlProfileImageThumb);
    } 
    if(!imageURI?.length) {
      getThumbFromMemories();
    }
  }, [folder]);

  return (
    <div className={styles.bucketWrapper}  style={{ ...theme.app?.border }}>
      <div className={styles.bucketImageWrapper}>
        <img src={imageURI} />
      </div>
      <div className={styles.bucketTitle} onClick={navToBucket}>{folder.title}</div>
      <BucketTealIconSVG className={styles.tealBucketIcon} />
      {folder?.receivingUsers?.length ? <div className={styles.folderAssignedUsersTextWrapper}>
            {folder?.receivingUsers?.length <= 2 ? 
              <p className={styles.folderAssignedUsersText}>
                {folder.receivingUsers[0].first}{!!folder.receivingUsers[1] ? `, and ${folder.receivingUsers[1].first}` : ''}
              </p>
            :
              <p className={styles.folderAssignedUsersText}>
                {folder.receivingUsers[0].first}, {folder.receivingUsers[1].first} {`+${folder?.receivingUsers?.length - 2} others`}
              </p>
            }
          </div> : <></>}
    </div>);

  function navToBucket() {
    dispatch(setSelectedBucket(folder));
    history.push('/bucket');
  }

  function getThumbFromMemories() {
    if(folder?.memories?.length) {
      for(var i=0; i < folder?.memories.length; i++) {
        const memory = folder.memories[i];
        if(memory?.assets?.length) {
          const assets = memory.assets.filter((asset) => {
            return asset.signedUrlThumb;
          });
          if(assets.length) {
            //@ts-ignore
            setImageURI(assets[0].signedUrlThumb);
            break;
          }
        }
      }
    }
  }
}