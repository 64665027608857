import { Route, Redirect } from 'react-router-dom';
import React from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { State } from '../store';

export function PrivateRoute({ children, ...rest }: any) {
  const user = useSelector((state: State) => state.user, shallowEqual);

  return (
    <Route
      {...rest}
      render={({ location }) =>
        (user.id || 0) > 0 ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

export function PublicRoute({ children, ...rest }: any) {
  const user = useSelector((state: State) => state.user, shallowEqual);

  const publicRoutesWhitelist = ['privacyPolicy', 'termsOfUse', 'ourStory', 'giving', 'tutorial', 'forgotPassword',''];

  const canAccess = (location: any) => {

    const notAuthenticated = (user.id || 0) === 0;

    const pathIsWhitelisted = () => {
      let isWhitelisted = false;
      if(location?.pathname) {
        const pathname = location.pathname.substring(1);

        isWhitelisted = publicRoutesWhitelist.indexOf(pathname) > -1;
      }

      return isWhitelisted;
    }

    return (notAuthenticated || pathIsWhitelisted());
  }

  return (
    <Route
      {...rest}
      render={({ location }) =>
        canAccess(location) ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}