import React, { useState, useEffect } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import styles from './About.module.scss';
import { ScrollToTopOnMount, AppProps } from '../../App';
import { State } from '../../store';
import { useHistory, Link } from 'react-router-dom';

export const AboutUsView = (props: AppProps) => {

  const theme = useSelector((state: State) => state.theme, shallowEqual);

  const state = useSelector(
    (state: State) => ({
      user: state.user,
    }),
    shallowEqual
  );

  const history = useHistory();

  const firstTime = useSelector((state: State) => state.firstTime, shallowEqual);

  useEffect(() => {
  }, [])

  return (
    <div className={styles.mainContainer}>
      <ScrollToTopOnMount />
      <div className={styles.aboutUsWrapper}>
        <h1 className={styles.header} style={theme.appColors.secondary}>Our Story</h1>
        <p style={theme.appText.text}>We believe that you never truly lose the people you love! Our joy in the memories we make together and the love we share is endless. Think of all the times you’ve said or heard “I wish I could hear their voice just once more” or “I wish I could stop time.”</p>
        <p style={theme.appText.text}>LuvBuckets arose from this impulse to carry our love and lasting legacies forward.</p>
        <p style={theme.appText.text}>LuvBuckets is a virtual time capsule that allows senders to create direct, intended, purposeful messages to be delivered to their loved ones. LuvBuckets preserves moments from person to person — safely and securely burying them in our virtual backyards until the sender’s specified opening date. Then the capsule will automatically unlock for the recipient to view, cherish, and keep with them from that point forward.</p>
        <p style={theme.appText.text}>With LuvBuckets, you can say the meaningful words you may be holding back so the people you value most in your life know your true thoughts. You can reach out to your loved ones in the present and in the future. Generations to come can continue to delight in family stories and personal histories that you share in this real-time account of your life at this specific point in time.</p>
        <p style={theme.appText.text} className={styles.aboutUsVideoText}>Please click <a href="https://youtu.be/YsfZwoCpQpE">HERE</a> to watch our "Welcome to LuvBuckets" video!!</p>
      </div>
    </div>
  );
};
