import moment from "moment";
import React from "react";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { UploadElem } from "../Uploads/UploadElem";
import styles from './MemoryAssets.module.scss';

export function MemoryAssetsView(props: any) {
  const location: any = useLocation();
  const memory = location?.state?.memory;

  useEffect(() => {
    //console.log(memory);
  }, []);

  return (
    <div className={styles.mainContainer}>
      <div className={styles.memoryInfoHeader}>
        <p className={styles.memoryTitle}>{memory.title}</p>
        <p className={styles.memoryDescription}>{memory.description}</p>
        <p className={styles.memoryStartDate}>{moment(memory.startDate).format('MMMM Do YYYY')}</p>
      </div>
      <div className={styles.memoryAssetListWrapper}>
        <div className={styles.memoryAssetListWrapperInner}>
          {memory?.assets?.length ? memory.assets.map((asset: any, index: number) => <UploadElem media={asset} key={index} />) : <></>}
        </div>
      </div>
    </div>
  );
}