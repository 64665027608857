import React, { Fragment, useEffect } from 'react';
import { Formik, FormikHelpers, Form } from 'formik';
import { object, string } from 'yup';
import { get } from 'object-path';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import PropTypes from 'prop-types';
import { FormControl, TextField } from '@material-ui/core';
import { useHistory, Link } from 'react-router-dom';
import { FormErrorText } from '../FormErrorText';
import { PasswordField } from '../PasswordField';
import { loginUser } from '../../services/AuthService';
import { updateUser } from '../../actions/user';
import { State } from '../../store';
import { LoadingOverlayElem } from '../Navigation/LoadingOverlay';
import { setOnFinishCallback, showMessage } from '../../services/LoadingService';
import styles from './LoginForm.module.scss';
import { hideLoadingSymbol } from '../../actions/request';
import { StyledButton } from '../FormElems/StyledButton';
import formStyles from '../FormElems/FormElems.module.scss';
import { InputElem } from '../FormElems/InputElem';

export const validationSchema = object().shape({
  email: string().required('Please enter a valid email address.').email('Please enter a valid email address.'),
  password: string().min(8, 'Password must be at least 8 characters long').required('Please enter a password'),
});

interface LoginFormInterface {
  email: string;
  password: string;
}

const initialValues: LoginFormInterface = {
  email: '',
  password: '',
};

export const LoginForm = (props: any) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const user = useSelector((state: State) => state.user, shallowEqual);

  const isLoading = useSelector((state: State) => state.request.isLoading);

  const theme = useSelector((state: State) => state.theme, shallowEqual);

  const handleOnSubmit = (values: LoginFormInterface, helpers: FormikHelpers<LoginFormInterface>) => {
    loginUser(values)
      .then((json: any) => {
        helpers.setSubmitting(false);
        if (!json) {
          return;
        }

        const validationErrors = get(json, 'errors.0.extensions.exception.validation', false);
        const genericError = get(json, 'errors.0.message', false);

        if (validationErrors) {
          Object.keys(validationErrors).map((key: string) => {
            helpers.setFieldError(key, validationErrors[key][0]);
          });
          dispatch(hideLoadingSymbol());
        } else if (genericError) {
          helpers.setFieldError('email', genericError);
          dispatch(hideLoadingSymbol());
        } else {
          setOnFinishCallback(() => {
            dispatch(updateUser(json.data.signIn));
          })
          showMessage();
        }
      })
      .catch(() => {
        helpers.setSubmitting(false);
      });
  };

  useEffect(() => {
    (() => {
      initialValues.email = user.email ? user.email : '';
    })();
  }, [user]);

  return (
    <>
      {isLoading && <LoadingOverlayElem message={'Thank You, you have been authenticated.'} />}
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnChange
        validateOnBlur
        enableReinitialize
        onSubmit={handleOnSubmit}
      >
        {({ errors, touched, values, handleChange, handleSubmit, setFieldTouched, isSubmitting }) => {
          const isTouched = !!Object.keys(touched).length;
          const isErrored = !!Object.keys(errors).length;
          const disabled: boolean = !isTouched || isErrored;

          return (
            <>
              <h1 style={{ ...theme.appText?.header }}>Log In</h1>
              <p style={{ ...theme.appText?.text }}>Please enter your email and password.</p>
              <Form className={styles.form}>
                <FormControl margin="normal" fullWidth>
                  <InputElem
                    id="email"
                    path="email"
                    placeholder="Email Address"
                    value={values.email}
                    handleChange={handleChange}
                    onKeyUp={(e: any) => setFieldTouched('email', true, false)}
                    errors={errors} 
                    touched={touched}
                    type={"text"}
                  />
                  <FormErrorText errors={errors} touched={touched} path="email" />
                </FormControl>
                <PasswordField
                  value={values.password}
                  label="Your Password"
                  id="password"
                  handleChange={handleChange}
                  onKeyUp={(e: any) => setFieldTouched('password', true, false)}
                  errors={errors}
                  touched={touched}
                />

                <StyledButton text={"Login"} isSubmitting={isSubmitting} disabled={disabled} onClick={handleSubmit} />
              </Form>
            </>
          );
        }}
      </Formik>
    </>
  );
};

LoginForm.propTypes = {
  navigate: PropTypes.object,
};
