import { ThemeAction } from "../actions/theme";
import { ThemeTypes, Themes } from "../themes/themeService";

const initState = Themes.TURQUOISE_THEME;

export function themeReducer(state = initState, action: ThemeAction) {
  switch (action.type) {
    case ThemeTypes.BLUE:
      return Themes.TURQUOISE_THEME;
    case ThemeTypes.DEFAULT:
      return Themes.TURQUOISE_THEME;
    default:
      return Themes.TURQUOISE_THEME;
  }
}