import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { State } from '../../store';
import styles from './TutorialView.module.scss';

export function TutorialView() {
  const theme = useSelector((state: State) => state.theme, shallowEqual);

  return (
    <div className={styles.mainContainer}>
      <h1 className={styles.header} style={theme.appColors.secondary}>Tutorial</h1>
      <div className={styles.previewImagesWrapper}>
        <div className={styles.previewImageWrapper}>
          <p>You've Been Invited to LuvBuckets!</p>
          <p>How to Register</p>
          <img src={require('../../assets/imgs/tutorial_01.png')} alt="" />
          <a className={styles.downloadLink} href={require('../../assets/LuvBucketsSignUp1.pdf')} target={"_blank"}>Download PDF</a>
        </div>
        <div className={styles.previewImageWrapper}>
          <p>You haven't been invited - </p>
          <p>Wait! What?!</p>
          <p>But you found us anyway!</p>
          <img src={require('../../assets/imgs/tutorial_02.png')} alt="" />
        </div>
        <div className={styles.previewImageWrapper}>
          <p>You're In!</p>
          <p>How to start using luvbuckets</p>
          <img src={require('../../assets/imgs/tutorial_03.jpg')} alt="" />
          <a className={styles.downloadLink} href={require('../../assets/LuvBucketsTutorial.pdf')} target={"_blank"}>Download PDF</a>
        </div>
      </div>
    </div>
  )
}