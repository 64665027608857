import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import styles from './LoadingOverlay.module.scss';
import { State } from '../../store';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { hideLoadingSymbol } from '../../actions/request';
import { toggleKeepLoadingSymbolNextCall, logKeepLoadingSymbol } from '../../helpers/retryReq';
import { setShowMessageCallback } from '../../services/LoadingService';
import { LogoSVG } from '../svgs/LogoSVG';

export function LoadingOverlayElem(props: any) {
  const theme = useSelector((state: State) => state.theme, shallowEqual);
  const [showMessage, setShowMessage] = useState(false);
  const dispatch = useDispatch();

  // NOTE!!!: setShowMessage needs to be the set func for a local state(useState) var coming from parent component using this component so this can properly trigger re render. 
  useEffect(() => {
    if (!!props.message) {
      toggleKeepLoadingSymbolNextCall();
      setShowMessageCallback((onFinish: any) => {
        setShowMessage(true);
        setTimeout(() => {
          setShowMessage(false);
          dispatch(hideLoadingSymbol());
          toggleKeepLoadingSymbolNextCall(false);
          if(onFinish) onFinish();
        }, 1000);
      });
    }

    return function cleanup() {
      logKeepLoadingSymbol();
      toggleKeepLoadingSymbolNextCall(false);
    }
  }, []);

  return (
    <div className={styles.wrapper}>
      <div className={styles.inner} />
      {!showMessage && 
      <div className={styles.loadingWrapper}>
        <FontAwesomeIcon className={styles.loadingSpinner} style={{ ...theme.app?.loadingSymbol }} icon={faCircleNotch} spin />
        <div className={styles.loadingLogo}>
          <LogoSVG style={{position: "absolute", top: 0, left: 0, width: 'inherit', height: 'inherit', aspectRatio: 1}} />
        </div>
      </div>  
      }
      {(!!props.message && showMessage) &&
        <p className={styles.loadingMsgText} style={theme.appText?.text}>
          {props.message}
        </p>
      }
    </div>
  );
}