import React from 'react';
import { State } from '../../store';
import { useSelector, shallowEqual } from 'react-redux';
import styles from './StyledButton.module.scss'
interface Props {
  disabled: boolean;
  isSubmitting: boolean;
  text: string;
  onClick: any;
}

export function StyledButton(props: Props) {
  const theme = useSelector((state: State) => state.theme, shallowEqual);
  return (
    <button className={styles.button} style={{ backgroundColor: (props.disabled || props.isSubmitting) ? theme.COLORS?.disabled?.main : theme.COLORS.secondary.main }} disabled={props.disabled || props.isSubmitting} onClick={props.onClick}>
      {props.text}
    </button>
  );
}