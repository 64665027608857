import React, { useEffect } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { Asset } from "../../reducers";
import { themeReducer } from "../../reducers/theme";
import { Folder } from "../../services/FolderService";
import { State } from "../../store";
import { MemoryReceivedAssetsLink, MemorySentAssetsLink } from "../Memories/MemoriesLinks";
import { BucketTealIconSVG } from "../svgs/BucketTealIconSVG";
import { RecipientUploadElem } from "../Uploads/RecipientUploadElem";
import { UploadElem } from "../Uploads/UploadElem";
import styles from './BucketsView.module.scss';

export default function BucketView(props: any) {
  const location: any = useLocation();

  const history = useHistory();

  const folder = useSelector((state: State) => state.selectedBucket, shallowEqual);

  useEffect(() => {
    if(!folder){
      history.push('/bucketsView');
    }
  }, []);

  const theme = useSelector((state: State) => state.theme, shallowEqual);

  const user = useSelector((state: State) => state.user, shallowEqual);

  return (
    <div className={styles.mainContainer}>
      <div className={styles.bucketViewHeaderWrapper}>
        <img src={folder.signedUrlProfileImageThumb} alt="" className={styles.bucketProfileImage} />
        <BucketTealIconSVG className={styles.tealBucketIconHeader} />
        <p className={styles.bucketHeaderTitle}>{folder.title}</p>
      </div>
      <div className={styles.bucketViewUploadListWrapper}>
        <div className={styles.uploadListInner}>
          {folder.memories?.length ? folder.memories.map((memory, index) => {
            return Number(folder.userId) === Number(user.id) ? <MemorySentAssetsLink memory={memory} key={index} /> : <MemoryReceivedAssetsLink memory={memory} key={index} />
          }) : <></>}
        </div>
      </div>
    </div>
  );
}