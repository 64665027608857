import { FirstTimeSignInAction } from "../reducers/FirstTimeSignInReducer";

export const SET_FIRST_TIME = 'SET_FIRST_TIME';

export const setFirstTimeLogin = (firstTime: boolean): FirstTimeSignInAction => {
  return {
    type: SET_FIRST_TIME,
    firstTime,
  };
};
