import { SET_FIRST_TIME } from '../actions/firstTimeSignIn';

export interface FirstTimeSignInAction {
  type: string;
  firstTime?: boolean;
}

const initState = false;

export function firstTimeSignInReducer(state = initState, action: FirstTimeSignInAction) {
  switch (action.type) {
    case SET_FIRST_TIME:
      return action.firstTime;
    default:
      return state;
  }
}
