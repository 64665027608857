import React from 'react';

import { State } from "../../store";
import { useSelector, shallowEqual } from "react-redux";


export function ExpandVideoSVG(props: any) {

  const theme = useSelector((state: State) => state.theme, shallowEqual);
  
  const onClick = props.onClick || (()=>{});

  return (
    <div onClick={onClick} style={props.style || {}}>
      <svg style={{position: "absolute", top: 0, left: 0, width: 'inherit', height: 'inherit'}} width="100%" height="100%">
        <g>
          <path fill={theme.appColors?.primary?.color} d="M29.92,11.5c0-0.18-0.04-0.36-0.1-0.52c0-0.01,0-0.02-0.01-0.02c-0.07-0.17-0.18-0.33-0.31-0.46
          c0,0,0,0,0,0s0,0,0,0c-0.13-0.13-0.29-0.23-0.46-0.31c-0.01,0-0.02,0-0.03-0.01c-0.16-0.06-0.33-0.1-0.51-0.1c0,0-0.01,0-0.01,0H20
          c-0.79,0-1.42,0.64-1.42,1.42s0.64,1.42,1.42,1.42h5.06L12.92,25.06V20c0-0.79-0.64-1.42-1.42-1.42s-1.42,0.64-1.42,1.42v8.5
          c0,0,0,0,0,0c0,0.18,0.04,0.36,0.1,0.52c0,0.01,0,0.02,0.01,0.02c0.07,0.17,0.18,0.33,0.31,0.46c0,0,0,0,0,0s0,0,0,0
          c0.13,0.13,0.29,0.24,0.46,0.31c0.01,0,0.02,0,0.02,0.01c0.16,0.06,0.33,0.1,0.52,0.1H20c0.79,0,1.42-0.64,1.42-1.42
          s-0.64-1.42-1.42-1.42h-5.06l12.13-12.13V20c0,0.79,0.64,1.42,1.42,1.42s1.42-0.64,1.42-1.42V11.5
          C29.92,11.5,29.92,11.5,29.92,11.5z"></path>
        </g>
      </svg>
    </div>
  );
}