export const MAX_TRIES_REACHED = 'MAX_TRIES_REACHED';
export const START_LOADING = 'START_LOADING';
export const STOP_LOADING = 'STOP_LOADING';

export function maxRetriesReached(reached: boolean) {
  return {
    type: MAX_TRIES_REACHED,
    maxTriesReached: reached,
  };
}

export function showLoadingSymbol() {
  return {
    type: START_LOADING,
  };
}

export function hideLoadingSymbol() {
  return {
    type: STOP_LOADING,
  };
}
