import styles from './JournalView.module.scss';
import React, { useEffect, useState } from "react";
import { shallowEqual, useSelector } from 'react-redux';
import { State } from '../../store';
import { getTextFromS3 } from '../../services/FileService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom';


export function ViewJournal() {
  const selectedAsset = useSelector((state: State) => state.selectedAsset.asset, shallowEqual);
  const [text, setText] = useState<any>("");
  const theme = useSelector((state: State) => state.theme, shallowEqual);
  const history = useHistory();


  useEffect(() => {
    console.log(selectedAsset);
    (async() => {
      await onAssetSet();
    })()
  }, [selectedAsset])

  return (
    <div className={styles.mainContainer}>
      <FontAwesomeIcon
        className={styles.closeIcon}
        onClick={goBack}
        icon={faTimes}
      />
      <p className={styles.journalTitle}>{selectedAsset?.title}</p>
      <div className={styles.textScrollView}>
        {(text.split('\n')).map((text: string, index: number) => <p style={{...theme.appText.text}} className={styles.journalText} key={index}>{text}</p>)}
      </div>
    </div>
  )

  async function onAssetSet() {
    if(!selectedAsset?.signedUrl) return;
    await getTextFromS3(selectedAsset.signedUrl, selectedAsset.fileName).then((res) => {
      //console.log(res);
      setText(res);
    })
  }

  function goBack() {
    history.goBack();
  }
}