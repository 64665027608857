import React, { useEffect, useState } from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import queryString from 'query-string';
import { updateUser } from '../../actions';
import { validateToken } from '../../services/AuthService';
import { State } from '../../store';

export const MagicLink: React.FC = ({ }) => {
  const [loading, setLoading] = useState(false);
  const { token } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const theme = useSelector((state: State) => state.theme, shallowEqual);

 useEffect(() => {
    setLoading(false);
    (async() => {
      if (!loading) {
        setLoading(true);
        await validateToken(token).then((res: any) => {
          if (res.data) {
            dispatch(updateUser(res.data.validateToken));
            setLoading(false);
            setTimeout(function () {
              history.push('/resetPassword');
            }, 1000);
          } else {
            setLoading(false);
            history.push('/')
          }
          const query = queryString.parse(location.search);
          const redirect = query.redirect ? `/${query.redirect}` : `/`;
          setLoading(false);
          
        });
      }
    })()
 }, [])

  return <h1 style={{ ...theme.appText?.header }}>Initiating magic link...</h1>;
};
