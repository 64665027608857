import React from "react";

export function AudioWavesSVG(props: any) {
  const onClick = props.onClick || (()=>{});

  const className = props.className || '';
  return (<div className={className} onClick={onClick}>
    <svg width="100%" height="100%" viewBox="0 0 143 68" fill="none">
        <circle cx="3.5" cy="37.5" r="3.5" fill="white"/>
        <rect x="10" y="17" width="5" height="38" rx="2.5" fill="white"/>
        <rect x="18" y="3" width="5" height="65" rx="2.5" fill="white"/>
        <rect x="26" y="17" width="5" height="38" rx="2.5" fill="white"/>
        <rect x="34" y="17" width="5" height="38" rx="2.5" fill="white"/>
        <rect x="42" y="9" width="5" height="56" rx="2.5" fill="white"/>
        <rect x="50" y="9" width="5" height="56" rx="2.5" fill="white"/>
        <rect x="58" y="9" width="5" height="56" rx="2.5" fill="white"/>
        <rect x="66" y="17" width="5" height="38" rx="2.5" fill="white"/>
        <rect x="74" width="5" height="65" rx="2.5" fill="white"/>
        <rect x="82" y="27" width="5" height="18" rx="2.5" fill="white"/>
        <rect x="90" y="17" width="5" height="38" rx="2.5" fill="white"/>
        <rect x="98" y="17" width="5" height="38" rx="2.5" fill="white"/>
        <rect x="106" y="29" width="5" height="14" rx="2.5" fill="white"/>
        <rect x="114" y="29" width="5" height="14" rx="2.5" fill="white"/>
        <rect x="122" y="29" width="5" height="14" rx="2.5" fill="white"/>
        <rect x="130" y="17" width="5" height="38" rx="2.5" fill="white"/>
        <rect x="138" y="17" width="5" height="38" rx="2.5" fill="white"/>
      </svg>
  </div>)
}