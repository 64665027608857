import React from 'react';
import { FormHelperText } from '@material-ui/core';
import { get } from 'object-path';
import { makeStyles } from '@material-ui/styles';
import { State } from '../../store';
import { useSelector, shallowEqual } from 'react-redux';

interface Props {
  touched?: any;
  errors?: any;
  path: string;
}

export const FormErrorText = (props: Props) => {
  const theme = useSelector((state: State) => state.theme, shallowEqual);
  const ErrorStyles = makeStyles({
    root: {
      color: theme.forms?.formErrorText?.color || 'orangered',
      alignSelf: "center"
    },
  });
  const styles = ErrorStyles();
  const touched = get(props.touched, props.path);
  const errored = get(props.errors, [props.path]);
  const showErrorText = touched && errored;
  return (
    <FormHelperText
      id={`error_${props.path}`}
      tabIndex={0}
      classes={styles}
      style={{ visibility: showErrorText ? 'visible' : 'hidden' }}
    >
      {errored}
    </FormHelperText>
  );
};
