import { Howl } from 'howler';

const instanceObj: any = {};

export function getPlaybackInstance(uri: string, setIsLoaded: any) {
  instanceObj[uri] = instanceObj[uri] ? instanceObj[uri] : new AudioPlaybackInstance(uri, setIsLoaded);
  return instanceObj[uri];
}

class AudioPlaybackInstance {
  soundObject = new Howl({
    src: [this.uri],
    preload: false,
    format: ['mp4'],
    onend: () => {
      if (this.onStatusChangeCallback) this.onStatusChangeCallback(false);
    },
    onload: () => {
      this.setIsLoaded(true);
      this.soundLoaded = true;
      this.play();
    },
    onpause: () => {
      if (this.onStatusChangeCallback) this.onStatusChangeCallback(false);
    },
    onplay: () => {
      if (this.onStatusChangeCallback) this.onStatusChangeCallback(true);
    },
  });

  public soundLoaded!: boolean;

  public soundPlaying!: boolean;

  public onStatusChangeCallback!: any;

  public play = () => {
    if (this.soundLoaded) this.soundObject.play();
  };

  public load = () => {
    this.soundObject.load();
  };

  public pause = () => {
    if (this.soundLoaded) this.soundObject.pause();
  };

  setOnStatusChangeCallback = (cb: any) => {
    this.onStatusChangeCallback = cb;
  };

  constructor(public uri: string, public setIsLoaded: any) {

    this.soundObject.on('load', () => {
      setIsLoaded(true);
      this.soundLoaded = true;
    });

    this.soundObject.on('loaderror', (err) => {
      console.log(err);
      // if(this.onEndCallback) this.onEndCallback();
    });
  }
}
