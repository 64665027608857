import styles from './GivingView.module.scss';
import React from "react";
import { State } from '../../store';
import { shallowEqual, useSelector } from 'react-redux';

export function GivingView() {

  const theme = useSelector((state: State) => state.theme, shallowEqual);

  return (
    <div className={styles.mainContainer}>
      <div className={styles.givingWrapper}>
        <h1 className={styles.header} style={theme.appColors.secondary}>Giving</h1>
        <img src={require('../../assets/imgs/giving_02.jpg')} className={styles.givingImageOne} />
        <img src={require('../../assets/imgs/giving_03.jpg')} className={styles.givingImageTwo} />
        <p style={theme.appText.text}>Here at LuvBuckets, we believe that giving back, giving of ourselves, and filling the buckets of those around us is at the core of our values. When you become part of the LuvBucketsfamily, we become one intertwined network constantly trying to affect the lives of others in a positive way. Every year we will select four worthy causes that a portion of every single monthly/yearly subscription will be donated to. Our FIRST featured charity will be Hayden’s House of healing, a place that is close to our hearts, as it has been a safe place for our family after the loss of our beloved nephew, Xavier Thomas Ross.</p>
        <img src={require('../../assets/imgs/giving_04.jpg')} className={styles.givingImageThree} />
        <p style={theme.appText.text}>The mission behind Hayden's House of Healing is to provide a safe, tranquil space for bereaved mothers, fathers, couples, children and families to heal and connect togetherthrough a variety of methods and activities offered to assist in the healing process.Hayden’s House of Healing developed once we saw the immense success and irreplaceable impact the 'Healing of the Heart' retreat had on the 50 women who had the opportunity to attend the first two years. A few months into our preparations for our second retreat, I had a vision that I just couldn’t let go of -and now that several retreats have transpired since those first two, I know it is my calling from God and from Hayden -the next project on his agenda in my life-long quest in honoring him. This space is a place of reflection, connection, and restoration. The journey through grief is complicated and complex. By providing the grieving community with a place they can present themselves in a candid, authentic and genuine way, we are aiding and facilitating in the healing process in ways the outside world cannot.Hayden’s House supports not only bereaved mothers in finding peace and solace, but also for fathers, families, couples and children to find the same comfort and understanding as they navigate their own personal loss. Our 2021 retreat dates have been released -please visit our retreat section on the website for more information on each retreat and links to apply.</p>     
        <a href={"https://www.haydenshouse.org"} target={"_blank"} className={styles.givingLink}>https://www.haydenshouse.org</a>
        <div className={styles.givingImageFooter}>
          <img src={require('../../assets/imgs/giving_01.png')} className={styles.givingImageFour} />
          <img src={require('../../assets/imgs/giving_06.jpg')} className={styles.givingImageFive} />
          <img src={require('../../assets/imgs/giving_05.jpg')} className={styles.givingImageSix} />
        </div>
      </div>
    </div>
  )
}