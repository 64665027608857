import styles from './ViewImage.module.scss';
import React from "react";
import { useLocation } from "react-router";

export function ViewImage(props: any) {
  const location: any = useLocation();

  const uri = location?.state?.uri;

  return (
    <div className={styles.mainContainer}>
      <img src={uri} alt="" className={styles.image} />
    </div>
  )
}